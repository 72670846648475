import { trim } from 'lodash';
import React, { memo } from 'react';

import { IconType, SharedFolderProps } from '../../types';
import { Icon } from '../Icon';
import { Layout } from '../Layout';
import { Link } from '../Link';
import { TruncateText } from '../Text';
import { Tooltip } from '../Tooltip';

export const Folder = memo(
  ({
    name,
    path,
    link,
    type = 'internal',
    isConcise = false,
  }: SharedFolderProps) => {
    const subfolders = trim(path, '/').split('/');
    const hasSubfolders = subfolders.length > 0 && subfolders[0] !== '';
    const lastFolder = subfolders[subfolders.length - 1];
    let content;
    if (link) {
      content = (
        <Tooltip tooltip={path}>
          <TruncateText color="text.primary">
            <Link {...link}>{hasSubfolders ? lastFolder : name}</Link>
          </TruncateText>
        </Tooltip>
      );
    } else if (isConcise) {
      content = (
        <Tooltip tooltip={path}>
          <TruncateText>{hasSubfolders ? lastFolder : name}</TruncateText>
        </Tooltip>
      );
    } else {
      content = <TruncateText>{subfolders.join('/')}</TruncateText>;
    }

    let icon: IconType;
    switch (type) {
      case 'drive':
        icon = 'drive';
        break;
      case 'external':
        icon = 'folder-move';
        break;
      case 'internal':
      default:
        icon = 'folder';
    }

    return (
      <Layout align="center" borderRadius="m" maxW="fit-content" spacing={2}>
        <Icon icon={icon} title={icon} />
        {content}
      </Layout>
    );
  },
);
