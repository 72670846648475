import { Nullable } from '~/types';

import { AttemptStatus, ConfigurationType, EndpointStatus } from './types';

export const getStatusChip = (status: EndpointStatus | AttemptStatus) => {
  switch (status) {
    case 'Active':
      return { text: 'Active', status: 'success' };
    case 'Suspended':
      return { text: 'Suspended', status: 'danger' };
    case 'Failed':
      return { text: 'Failed', status: 'danger' };
    case 'Success':
      return { text: 'Success', status: 'success' };
  }
};

export const getDisabledTooltip = (
  configuration: Nullable<ConfigurationType>,
) => {
  if (configuration) {
    if (!configuration?.url) {
      return 'Please provide a valid URL';
    } else if (Object.keys(configuration?.events).length === 0) {
      return 'Select at least one event';
    }
  }
};
