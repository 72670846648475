import React, { useEffect } from 'react';

import { useToggle } from '../../hooks';
import { sizes } from '../../tokens';
import { blurOnClick } from '../../utils';
import { Layout } from '../Layout';
import { AccordionItemHeader } from './AccordionItemHeader';
import { BaseItem, BorderMode, Item, PaddingMode } from './types';

interface Props<D = unknown> {
  item: Item<D>;
  border?: BorderMode;
  isIndented?: boolean;
  padding?: PaddingMode;
  search?: string;
  onToggle?: (isExpanded: boolean, item: BaseItem<D>) => void;
}

export const AccordionItem = <D,>({
  border = 'all',
  item,
  isIndented = false,
  padding = 'all',
  onToggle,
  search,
}: Props<D>) => {
  const { content, details, isExpanded = false, isLoading, title } = item;

  const [isVisible, toggle, on, off] = useToggle(isExpanded);

  useEffect(() => {
    if (!isVisible && isExpanded) {
      on();
    } else if (isVisible && !isExpanded) {
      off();
    }
  }, [isExpanded]);

  const handleAccordionHeaderClick = (event: MouseEvent) => {
    onToggle?.(!isVisible, item);
    toggle();
    blurOnClick(event);
    event.preventDefault();
  };

  const borderX = border === 'all' ? 'border.divider' : 'none';
  const borderY = ['all', 'vertical'].includes(border)
    ? 'border.divider'
    : 'none';
  const p = padding === 'all' ? 4 : 0;

  return (
    <Layout
      as="details"
      borderBottom={borderY}
      borderLeft={borderX}
      borderRight={borderX}
      borderTop={borderY}
      direction="column"
      open={isVisible && !isLoading}
    >
      <AccordionItemHeader
        isVisible={isVisible && !isLoading}
        item={item}
        padding={padding}
        search={search}
        onClick={handleAccordionHeaderClick}
      />
      <Layout
        as="section"
        aria-labelledby={`${title}-header`}
        bg="background"
        direction="column"
        display={isVisible ? undefined : 'none'}
        id={`${title}-panel`}
        ml={isIndented ? `${sizes['icon.m'] + 8}px` : undefined} // indent with: icon size + spacing
        pb={p}
        px={p}
      >
        {details}
        {content}
      </Layout>
    </Layout>
  );
};
