import {
  black1,
  black2,
  bold,
  evisortBlue,
} from '../../../assets/shared-styles/general';

const styles = {
  addNewParty: {
    display: 'flex',
    alignItems: 'center',
    color: evisortBlue,
    fontSize: '13px',
    fontWeight: bold,
    minWidth: '120px',
    cursor: 'pointer',
    '& svg': {
      marginRight: '4px',
    },
  },
  headerExtraOptionsColumn: { width: '220px' },
  headerOptionsWrapper: {
    display: 'flex',
  },
  partyTable: {
    width: '100%',
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
    color: black1,
    textAlign: 'left',
    '& th': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      padding: '16px 0',
      color: black2,
      fontSize: '15px',
      fontWeight: bold,
    },
    '& td': {
      padding: '12px 0',
      color: black2,
      fontSize: '15px',
      wordBreak: 'break-all',
    },
    '& tr': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      '&:last-child': {
        border: 0,
      },
    },
  },
};

export default styles;
