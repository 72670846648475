import { LabeledOperator } from '~/components/Shared/Filters_DEPRECATED';
import { FieldId } from '~/evifields';
import { eviSearch } from '~/services';
import { ColumnSortOrder, Nullable, Uuid } from '~/types';

import { JsonApiGetResponse, JsonApiListResponse } from '../ApiTypes';
import { ChartData, CreateChartParams } from '../types/dashboard';
import { ApiChartData } from '../types/dashboard/charts';
import { toSortParam } from '../utils';
import { SearchFilterType } from './searchV3';

export type SearchType = 'ticket' | 'workflow';

type FieldValueTypes = string | string[] | number;
export interface SearchParams {
  query: Array<any>;
  filters?: Array<FieldId>;
  sortBy: ColumnSortOrder;
  page: number;
  pageSize: number;
  type: SearchType;
}

export type SearchItem<V = FieldValueTypes> = {
  full_text_matches: any[]; //TBD
  permissions: unknown; //TBD
  selected_field_values: Record<FieldId, V>;
  id: Uuid;
  type: SearchType;
  highlights: unknown; //TBD
};

interface SearchResponseResult {
  query: Array<any>; //TBD
  results: Array<SearchItem>;
}

interface Links {
  next: string;
  previous: string;
}

interface SearchMeta {
  total: number;
  page: {
    size: number;
    current: number;
    next: number;
  };
}

export interface SearchResult<V = FieldValueTypes> {
  results?: SearchItem<V>[];
  meta?: SearchMeta;
}

export interface SearchFilterApiResponse {
  section: string;
  label: string;
  help_text: string;
  type: SearchFilterType;
  operators: LabeledOperator[];
  is_chartable: boolean;
  is_requestable_column: boolean;
}

interface SuggestionResponse {
  label: string;
  value: string;
  count: number;
}

interface SuggestionsMeta {
  select_all_count: number;
}

interface ExportOptionsParams {
  query: Array<any>;
  type: SearchType;
}

interface ExportExcelParams {
  query: Array<any>;
  type: SearchType;
  fields: Nullable<Array<string>>;
}

export const search: (params: SearchParams) => Promise<SearchResult> = async ({
  page,
  type,
  pageSize,
  sortBy,
  query,
  filters = [],
}) => {
  const response: JsonApiGetResponse<
    'search',
    SearchResponseResult,
    SearchMeta,
    void,
    Links
  > = await eviSearch.post(
    '/search',
    {
      data: {
        type,
        attributes: {
          query,
          filters,
        },
      },
    },
    {
      params: {
        'page[number]': page,
        'page[size]': pageSize,
        sort: toSortParam(sortBy),
      },
    },
  );

  return {
    results: response?.data.attributes.results.map((searchItem) => searchItem),
    meta: response?.meta,
  };
};

export const fetchFiltersV2: ({
  type,
}: {
  type: SearchType;
}) => Promise<JsonApiListResponse<'filter', SearchFilterApiResponse>> = async ({
  type,
}: {
  type: 'ticket' | 'workflow';
}) => {
  return eviSearch.get('/filters', { params: { entity: type } });
};

export const getFilterSuggestionsV2 = async ({
  filterId,
  type,
  query,
}: {
  filterId: string;
  type: SearchType;
  query: any;
}) => {
  const response = (await eviSearch.get(`/filters/${filterId}/suggestions`, {
    params: { entity: type, query },
  })) as JsonApiListResponse<
    'suggestion',
    SuggestionResponse,
    SuggestionsMeta,
    void
  >;

  return {
    data: response.data,
    meta: response.meta,
  };
};

const createChart = ({
  uuid,
  query,
  bucket_size = 10,
  interval,
}: CreateChartParams): Promise<JsonApiGetResponse<SearchType, ApiChartData>> =>
  eviSearch.post(`/search/chart/${uuid}`, {
    data: {
      type: 'ticket',
      attributes: { query, bucket_count: bucket_size, interval },
    },
  });

export const getChartDataV2 = async (
  params: CreateChartParams,
): Promise<ChartData> => {
  const response = await createChart(params);

  const {
    buckets: apiBuckets,
    bucket_count: bucketCount,
  } = response!.data.attributes;
  return {
    bucketCount,
    buckets: apiBuckets.map((b) => ({
      label: b.label,
      value: b.count,
      bucketValue: b.value,
    })),
    meta: response?.meta,
  };
};

export const getSearchExportOptionsV2 = async ({
  query,
  type,
}: ExportOptionsParams) => {
  const response = await eviSearch.post(`/search/export-options`, {
    data: { attributes: { query, type } },
  });
  return response.data.attributes;
};

export const exportExcelV3 = ({
  type,
  query,
  fields = [],
}: ExportExcelParams) => {
  return eviSearch.post(
    '/search/export',
    {
      data: { attributes: { query, type, fields } },
    },
    {
      responseType: 'arraybuffer',
      // @ts-ignore RTKQ missing mapping of this property
      fullResponse: true,
    },
  );
};
