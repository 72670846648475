import React, { useState } from 'react';

import { trackSegment } from '~/components/SegmentAnalytics';
import EcModal from '~/components/Shared/EcModal';
import { DEFAULT_ACCEPTED_FILES } from '~/constants/files';
import {
  ContentContainer,
  FileInput,
  Files,
  FolderInput,
  Layout,
  Panel,
  useToast,
} from '~/eds';
import { FlagType, useFlag } from '~/flags';
import { useCurrentUser } from '~/hooks';
import { api, coerceRtkqError } from '~/redux';
import { Nullable } from '~/types';
import { MODAL_FOLDER_TREE } from '~/types/modal.types';
import { getClientInfo, getUserClientInfo } from '~/utils/user';

import { FormFields } from './FormFields';
import { FormFieldConfig, Location, UploadFormField } from './types';

interface UploadModalProps {
  files: File[];
  onHide: () => void;
  onFilesChange: (files: File[]) => void;
  onRemoveFile: (removedFile: File, removedFileIndex?: number) => void;
}

export const UploadDocumentsPanel = ({
  files,
  onHide,
  onFilesChange,
  onRemoveFile,
}: UploadModalProps) => {
  const user = useCurrentUser();
  const hasSearchFolderPanel = useFlag(FlagType.SearchFolderPanel);
  const { toast } = useToast();

  const [locationModal, setLocationModal] = useState<string | null>(null);

  const [_hasUploadPermission, setHasUploadPermission] = useState(null);

  const [uploadLocation, setUploadLocation] = useState<Nullable<Location>>(
    null,
  );

  const [uploadFormFields, setUploadFormFields] = useState<UploadFormField[]>(
    [],
  );

  const [enableInfoSection, setEnableInfoSection] = useState(false);

  const formFields: FormFieldConfig[] = user.client_config.upload_form_fields.map(
    ({ allow_new_options, ...field }) => ({
      ...field,
      allowNewOptions: allow_new_options,
    }),
  );

  const {
    data: folderTree,
    isFetching: isLoadingFolderTree,
    error: folderTreeError,
  } = api.endpoints.getFolderTree.useQuery(undefined);

  const [accessFolder] = api.endpoints.getFolderVisibility.useLazyQuery();
  const [uploadFiles] = api.endpoints.addUploadFiles.useMutation();

  const handleOnHide = () => {
    setUploadLocation(null);
    onHide();
    setUploadFormFields([]);
    setEnableInfoSection(false);
  };

  const handleLocationChange = (location: Location) => {
    const folderId = location.id;
    accessFolder({ folderId }).then((res: any) => {
      if (res.error) {
        toast({
          message: res.error.message,
          status: 'danger',
        });
      } else {
        setUploadLocation(location);
        setHasUploadPermission(res.data.canModifyFolder);
      }
    });
  };

  const handleOnChange = (formData: UploadFormField) => {
    const { fieldId, value } = formData;

    const fieldIndex = uploadFormFields.findIndex(
      (field) => field.fieldId === fieldId,
    );

    if ((Array.isArray(value) && value.length === 0) || !value) {
      setUploadFormFields(
        uploadFormFields.filter((_, index) => index !== fieldIndex),
      );
    } else if (fieldIndex === -1) {
      setUploadFormFields([...uploadFormFields, { fieldId, value }]);
    } else {
      setUploadFormFields(
        uploadFormFields.map((item, index) =>
          index === fieldIndex ? { fieldId, value } : item,
        ),
      );
    }
  };

  const locationName = uploadLocation
    ? uploadLocation.name
    : folderTree?.name ?? '';

  const folderId = uploadLocation
    ? uploadLocation.id
    : folderTree?.id
    ? folderTree.id
    : '2';

  const extendedFiles =
    files && files.length > 0
      ? files.map((file) => {
          //Remove this 2, but confirm how to prevent foldertree from undefined?
          // @ts-ignore folderId is not a property of File
          file.folderId = uploadLocation
            ? uploadLocation.id
            : folderTree?.id
            ? folderTree.id
            : '2';
          return file;
        })
      : [];

  const children = (
    <>
      {enableInfoSection ? (
        <Layout preset="sections">
          {!folderTreeError && (
            <FolderInput
              folder={{
                name: locationName,
                path: uploadLocation?.path ?? '',
                isConcise: true,
              }}
              onChange={() => setLocationModal(MODAL_FOLDER_TREE)}
              isLoading={isLoadingFolderTree}
            />
          )}
          <ContentContainer
            placeholderContent={
              !user.client_config.upload_form_fields
                ? { message: 'FormFields are not available' }
                : undefined
            }
          >
            <FormFields
              formFields={formFields}
              uploadFormFields={uploadFormFields}
              onChange={handleOnChange}
            />
          </ContentContainer>
        </Layout>
      ) : (
        <Layout preset="sections">
          <FileInput
            accept={DEFAULT_ACCEPTED_FILES}
            isMulti={true}
            name="Upload Files Dropzone"
            onChange={(uploadedFiles) => {
              onFilesChange(uploadedFiles ?? []);
            }}
            placeholder="Drag document(s) here to upload them."
            value={null}
          />
          <Files
            files={files}
            onRemoveFile={onRemoveFile}
            title="Upload Files"
          />
        </Layout>
      )}
      {locationModal === MODAL_FOLDER_TREE && (
        <EcModal
          modalType={MODAL_FOLDER_TREE}
          width="540px"
          title="Choose Location"
          uploadLocation={uploadLocation}
          uploadLocationPath={uploadLocation?.path ? uploadLocation.path : []}
          folderIdsSelected={[]}
          handleUploadLocationChange={handleLocationChange}
          confirmButtonText="Choose Location"
          hideModal={() => {
            setLocationModal(null);
          }}
          enableSearch={hasSearchFolderPanel}
        />
      )}
    </>
  );

  const disableTooltip = !enableInfoSection
    ? 'Please select your folder location'
    : undefined;

  return (
    <Panel
      width="m"
      children={children}
      position="right"
      title="Upload"
      hidden={{
        placement: 'left',
        onHide: handleOnHide,
      }}
      actions={[
        {
          text: 'Next',
          onClick: () => {
            setEnableInfoSection(true);
          },
          level: 'primary',
          icon: 'arrow-right',
          iconPosition: 'right',
          disabled: enableInfoSection,
        },
      ]}
      footer={{
        actions: [
          {
            text: 'Upload',
            disabled: Boolean(disableTooltip),
            tooltip: disableTooltip,

            onClick: () => {
              trackSegment('User Uploads Document', {
                fileNames: files.map((file) => file.name),
                groupId: getClientInfo(user),
                userId: getUserClientInfo(user),
              });
              uploadFiles({
                files: extendedFiles,
                folderId: String(folderId),
                uploadFormFields,
              }).then((res) => {
                if ('error' in res) {
                  toast({
                    message: coerceRtkqError(res.error)?.message,
                    status: 'danger',
                  });
                } else {
                  toast({
                    message: 'Files uploaded successfully',
                    status: 'success',
                  });
                }
              });
              handleOnHide();
            },
            level: 'primary',
          },
          {
            text: 'Cancel',
            onClick: handleOnHide,
          },
        ],
      }}
    />
  );
};
