import uuid from 'uuid';

import { UploadFormField } from '~/features/upload';
import { pilot } from '~/services';

interface Request {
  files: File[];
  folderId: string;
  uploadFormFields: UploadFormField[];
}

interface Response {
  status: 'success' | 'failed';
  file: File;
}

type UploadedFiles = Response[];

export const addUploadFiles = async (
  request: Request,
): Promise<UploadedFiles> => {
  const { files, uploadFormFields, folderId } = request;

  const responses = await Promise.all(
    files.map(async (file) => {
      const formData = mapRequestToFormData(file, folderId, uploadFormFields);

      try {
        await pilot.post('/document/upload/', formData);
        return { status: 'success' as const, file };
      } catch (error) {
        return { status: 'failed' as const, file };
      }
    }),
  );

  return (responses as unknown) as UploadedFiles;
};

const mapRequestToFormData = (
  file: File,
  folderId: string,
  uploadFormFields: UploadFormField[],
) => {
  const transformedUploadFormFields = uploadFormFields.map(
    ({ fieldId, value }) => ({ id: String(fieldId), value }),
  );
  const formData = new FormData();

  formData.append('id', uuid.v4());
  formData.append(
    'upload_form_data',
    JSON.stringify(transformedUploadFormFields),
  );
  formData.append('file', file);
  formData.append('filename', file.name);
  formData.append('folder', folderId);
  formData.append('size_in_bytes', String(file.size));

  return formData;
};
