import uuid from 'uuid';

import { Attempt } from '~/features/webhooks';

//import { courier } from '~/services';

export const getAttempts = async () => {
  // const response = await courier.get('/delivery-attempts');

  const mockedAttempts: Attempt[] = [
    {
      id: uuid.v4(),
      endpointUrl: 'https://example.com',
      eventType: 'ticket created',
      status: 'Success',
      messageId: '1234',
      dateSent: new Date('2023-10-13'),
      requestBody: {
        id: 'we_2432543643654654654654645654',
        object: 'webhook_endpoint',
        api_version: null,
        application: null,
        created: 12335435435,
        description: 'Testing here',
      },
      responseExample: {
        id: 'we_2432543643654654654654645654',
        object: 'webhook_endpoint',
        api_version: null,
        application: null,
        created: 12335435435,
        description: 'Testing here',
        metadata: {
          key1: 'value1',
          key2: 'value2',
        },
        status: 'active',
        url: 'https://example.com/webhook',
        last_attempt: {
          id: 'attempt_1234567890',
          timestamp: 1234567890,
          status: 'success',
        },
        retry_count: 3,
        secret: 'whsec_1234567890abcdef',
        endpoint_type: 'http',
      },
    },
  ];

  return mockedAttempts;
};
