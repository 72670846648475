import { SearchV3 } from '~/features/search/SearchV3';
import { FlagType, useFlag } from '~/flags';

import DeprecatedSearch from './Search_DEPRECATED';

function Page() {
  const hasSearchV3 = useFlag(FlagType.SearchV3);

  return hasSearchV3 ? <SearchV3 /> : <DeprecatedSearch />;
}

export default Page;
