import { Endpoint } from '~/features/webhooks';
import { courier } from '~/services';
import { PilotId } from '~/types';

interface Request {
  clientId: PilotId;
  userId: PilotId;
  endpoint: Endpoint;
}

export const suspendEndpoint = async ({
  clientId,
  userId,
  endpoint,
}: Request): Promise<boolean> => {
  const { id } = endpoint;

  //TODO: update payload with params once defined by the backend
  const params = {
    clientId,
    userId,
    endpointId: id,
    status: 'suspended',
  };

  const response = await courier.patch('/endpoints', { params });

  return Boolean(response);
};
