import { decamelizeKeys } from 'humps';

import { pilot } from '~/services';

export const getExternalPartyList = (clientId, page) => {
  const params = { page, internal: false };
  return pilot.get(`client/${clientId}/party-lists/`, { params });
};

export const getInternalPartyList = (clientId, page) => {
  const params = { page, internal: true };
  return pilot.get(`client/${clientId}/party-lists/`, { params });
};

export const deleteParty = (clientId, internal, party) => {
  const params = { internal, partyName: party.name };
  return pilot.remove(`client/${clientId}/party/`, { params });
};

export const updatePartyList = (clientId, internal, party, oldName) => {
  const data = decamelizeKeys({ internal, oldName, party });
  return pilot.post(`client/${clientId}/update-party-list/`, data);
};
