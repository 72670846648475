/* eslint-disable @typescript-eslint/no-unused-vars */
import { everest } from '~/services';

import { ShareSettingsPayload } from './types';

const transformShareSettings = (
  response: ShareSettingsPayload,
  currentUserId: number,
) => {
  const userIds = response.data.attributes.user_ids;
  const departmentIds = response.data.attributes.department_ids;

  const visibility =
    userIds.length === 0 && departmentIds.length === 0
      ? 'OPEN'
      : userIds.length === 1 &&
        userIds.includes(currentUserId) &&
        departmentIds.length === 0
      ? 'PRIVATE'
      : 'SHARED';
  return {
    isPublished: true,
    visibility,
    userIds,
    departmentIds,
  };
};

const getDefaultData = (userId: number) => {
  return {
    isPublished: false,
    visibility: 'PRIVATE',
    userIds: [userId],
    departmentIds: [],
  };
};

export const getWorkflowShareSettings = async ({
  workflowId,
  currentUserId,
}: {
  workflowId: string;
  currentUserId: number;
}) => {
  try {
    const response = await everest.get(
      `/workflows/${workflowId}/visibility-settings/`,
    );
    return transformShareSettings(response, currentUserId);
  } catch (e: any) {
    if (e?.response?.status === 404) {
      // if the share setting doesn't exist yet, it's private
      return getDefaultData(currentUserId);
    }
    throw e;
  }
};
