import { courier } from '~/services';

interface Request {
  configuration: {
    url: string;
    description?: string;
    events: Record<string, string[]>;
  };
}

interface ServerRequest {
  type: string;
  name: string;
  url: string;
  description?: string;
  events: string[];
}

export const createEndpoint = async ({
  configuration,
}: Request): Promise<boolean> => {
  const { url, description, events } = configuration;

  const transformedEvents = [
    ...Object.keys(events).reduce<string[]>((acc, key) => {
      return [...acc, ...events[key]];
    }, []),
  ];

  const params: ServerRequest = {
    type: 'HTTP',
    name: url,
    url,
    description,
    events: transformedEvents,
  };

  const response = await courier.post('/endpoints', params);
  return Boolean(response);
};
