import type { Citation, ResolvedCitationEntity } from '~/features/ask-anything';
import { RoutePathType } from '~/routing';

import { getTicketDocument } from '../ticket';

export const resolveCitation = async (
  entity: Citation['entity'],
): Promise<ResolvedCitationEntity | undefined> => {
  switch (entity.type) {
    case 'ticket':
      const ticketDocument = await getTicketDocument({ ticketId: entity.id });
      return {
        lastModified: new Date(ticketDocument.modifiedDate),
        link: RoutePathType.WorkflowTicketsTicketReviewerVersionNumber.replace(
          ':ticketId',
          entity.id,
        ).replace(':versionNumber', `${ticketDocument.version}`),
      };
  }
};
