import { UploadStatusType } from '~/enums';
import { AlgoStatus, UploadFile } from '~/features/upload';
import { pilot } from '~/services';
import { Nullable, PilotId } from '~/types';

interface Request {
  page: number;
  pageSize: number;
}

interface Response {
  next: string;
  previous: Nullable<string>;
  count: number;
  results: Array<{
    id: PilotId;
    doc_id: PilotId;
    document_name: string;
    date_added: string;
    size: number;
    file_type: string;
    processing_status: UploadStatusType;
    algo_status_message: Record<string, AlgoStatus>;
  }>;
}

export const getUploadFiles = async ({
  page,
  pageSize,
}: Request): Promise<{ count: number; results: UploadFile[] }> => {
  const response: Response = await pilot.get(
    `/user/my-uploads?page=${page}&pageSize=${pageSize}`,
  );

  return {
    results: response.results
      ? response.results.map((value) => {
          const algoStatuses = Object.entries(value.algo_status_message).map(
            ([key, value]) => {
              return {
                title: key,
                status: value.status,
                message: value.message,
                count: value.count,
              };
            },
          );
          return {
            id: value.id,
            name: value.document_name,
            dateAdded: new Date(value.date_added),
            fileType: value.file_type,
            size: value.size,
            status: value.processing_status,
            algoStatuses: algoStatuses,
          };
        })
      : [],
    count: response.count,
  };
};
