import React from 'react';
import { useDispatch } from 'react-redux';

import { Layout, Table } from '~/eds';
import { slices } from '~/redux';

import { Endpoint } from './types';
import { getStatusChip } from './utils';

interface Props {
  endpoints: Endpoint[];
  isLoading: boolean;
}

export const Endpoints = ({ endpoints, isLoading }: Props) => {
  const dispatch = useDispatch();

  const columns = [
    {
      key: 'endpointUrl',
      title: 'Endpoint URL',
      cellType: 'link',
      mapCellProps: (endpoint: Endpoint) => ({
        pathname: endpoint.endpointUrl,
        text: endpoint.endpointUrl,
        onClick: () => {
          dispatch(slices.webhooks.actions.setActiveEndpoint(endpoint));
          dispatch(slices.webhooks.actions.setActiveEndpointType('view'));
        },
      }),
    },
    {
      key: 'status',
      title: 'Status',
      cellType: 'chips',
      mapCellProps: (endpoint: Endpoint) => {
        const { text, status } = getStatusChip(endpoint.status);
        return {
          chips: [
            {
              text,
              status,
            },
          ],
        };
      },
    },
    {
      key: 'description',
      title: 'Description',
      cellType: 'text',
      mapCellProps: (endpoint: Endpoint) => ({
        text: endpoint.description,
      }),
    },
    {
      key: 'selectedEvents',
      title: 'Selected Events',
      cellType: 'text',
      mapCellProps: (endpoint: Endpoint) => ({
        text: `${endpoint.selectedEvents.length}`,
      }),
      width: 'm',
    },
    {
      key: 'dateCreated',
      title: 'Created',
      cellType: 'datetime',
      mapCellProps: (endpoint: Endpoint) => ({
        datetime: endpoint.dateCreated,
        format: 'full',
      }),
      width: 'm',
    },
    {
      key: 'dateModified',
      title: 'Modified',
      cellType: 'datetime',
      mapCellProps: (endpoint: Endpoint) => ({
        datetime: endpoint.dateModified,
        format: 'full',
      }),
      width: 'm',
    },
  ];

  const handleViewDetails = (endpoint: Endpoint) => {
    dispatch(slices.webhooks.actions.setActiveEndpoint(endpoint));
    dispatch(slices.webhooks.actions.setActiveEndpointType('view'));
  };

  return (
    <Layout preset="sections">
      <Table
        data={endpoints}
        isLoading={isLoading}
        columns={columns}
        options={{
          enableManageColumns: false,
          enableSelectRows: false,
          enableSearch: true,
        }}
        rowActions={[
          {
            label: 'View Details',
            onClick: handleViewDetails,
          },
          {
            label: 'View Attempts',
            onClick: (endpoint: Endpoint) => {
              dispatch(slices.webhooks.actions.setActiveEndpoint(endpoint));
              dispatch(slices.webhooks.actions.setTab('attempts'));
            },
          },
          {
            label: 'Edit',
            onClick: (endpoint: Endpoint) => {
              dispatch(slices.webhooks.actions.setActiveEndpointType('edit'));
              dispatch(slices.webhooks.actions.setActiveEndpoint(endpoint));
            },
          },
          {
            label: 'Suspend',
            onClick: (endpoint: Endpoint) => {
              dispatch(
                slices.webhooks.actions.setActiveEndpointType('suspend'),
              );
              dispatch(slices.webhooks.actions.setActiveEndpoint(endpoint));
            },
          },
          {
            label: 'Delete',
            onClick: (endpoint: Endpoint) => {
              dispatch(slices.webhooks.actions.setActiveEndpointType('delete'));
              dispatch(slices.webhooks.actions.setActiveEndpoint(endpoint));
            },
          },
        ]}
      />
    </Layout>
  );
};
