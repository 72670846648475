import React from 'react';
import { Day as DayPickerDay, DayProps } from 'react-day-picker';

import { useView } from './ViewContext';

export const Day = (props: DayProps) => {
  const { view } = useView();

  if (view !== 'day') {
    return null;
  }

  return <DayPickerDay {...props} />;
};
