import React, { useState } from 'react';
import { connect } from 'react-redux';

import LockIcon from '~/components/Shared/Icons/LockIcon';
import ErrorIcon from '~/components/Workflow/shared/ErrorIcon';
import { getErrors } from '~/reducers/workflow';
import { FlexLayout, PageLayout } from '~/ui';

import Conditions from './Conditions';
import Fields from './Fields';

function Sidebar({
  // connected
  disabled,
  errors,
  shouldValidate,
}) {
  const [selectedTab, setSelectedTab] = useState('fields');

  const tabsData = {
    fields: {
      component: <Fields />,
      iconRight: disabled ? (
        <LockIcon />
      ) : (
        <ErrorIcon
          errors={errors.fields}
          shouldRender={shouldValidate}
          variant="yellow"
        />
      ),
      label: 'Fields',
      value: 'fields',
    },
    conditions: {
      component: <Conditions />,
      iconRight: disabled ? (
        <LockIcon />
      ) : (
        <ErrorIcon
          errors={errors.conditions}
          shouldRender={shouldValidate}
          variant="yellow"
        />
      ),
      label: 'Conditions',
      value: 'conditions',
    },
  };

  return (
    <FlexLayout
      flexDirection="column"
      flex="1 1 auto"
      pb={5}
      space={3}
      sx={{ overflow: 'hidden' }}
    >
      <PageLayout.SidebarTabs
        selectedTab={selectedTab}
        tabs={Object.values(tabsData)}
        onSelectTab={setSelectedTab}
      />
      {tabsData[selectedTab].component}
    </FlexLayout>
  );
}

function mapStateToProps({ builder, workflow }) {
  return {
    disabled: !builder.shouldEnableAllComponents,
    errors: getErrors(workflow, builder),
    shouldValidate: builder.shouldValidate,
  };
}

export default connect(mapStateToProps)(Sidebar);
