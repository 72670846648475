import React, { useEffect, useMemo } from 'react';

import { trackSegment } from '~/components/SegmentAnalytics';
import {
  Box,
  ContentContainer,
  DescriptionListAccordion,
  INPUT_DEBOUNCE_MS,
  Layout,
  SearchInput,
  useThrottledState,
} from '~/eds';
import { api } from '~/redux';
import { Field, Section } from '~/redux/api/methods';
import { Nullable, PilotId } from '~/types';

import { filterSections } from './Fields.utils';

type Props = {
  docHandlerId?: PilotId;
};

export const Fields = ({ docHandlerId }: Props) => {
  const {
    data,
    isFetching,
    isError,
  } = api.endpoints.getDocumentInformation.useQueryState(docHandlerId!, {
    skip: !docHandlerId,
  });
  const [throttledSearch, search, setSearch] = useThrottledState(
    '',
    INPUT_DEBOUNCE_MS,
  );
  const documentId = data?.documentId;

  useEffect(() => {
    if (throttledSearch) {
      trackSegment('searchFields', {
        name: documentId,
        value: throttledSearch,
      });
    }
  }, [throttledSearch]);

  const sections = useMemo(() => {
    if (isError || !data) return [];

    return data.sections.map(toAccordionSection);
  }, [isError, data]);

  const filteredSections = useMemo(() => {
    return filterSections(sections, search);
  }, [sections, search]);

  const handleSearch = (text: Nullable<string>) => {
    setSearch(text ?? '');
  };

  const loadingContent = { isLoading: isFetching, message: 'Loading fields' };
  const placeholderContent = isError
    ? {
        icon: 'field-clause' as const,
        message:
          'Something went wrong when retrieving the fields. Please refresh and load again or contact your admin.',
      }
    : filteredSections.length === 0 && search
    ? { title: 'No matching fields', image: 'empty-no-search' }
    : undefined;

  return (
    <Layout h="100%" direction="column">
      <Box p={4}>
        <SearchInput
          disabled={isError || isFetching}
          placeholder="Search fields"
          name="search"
          onChange={(text) => {
            handleSearch(text);
          }}
          value={search}
        />
      </Box>
      <Box overflowY="auto">
        <ContentContainer
          loadingContent={loadingContent}
          placeholderContent={placeholderContent}
        >
          <DescriptionListAccordion
            isEmbedded={true}
            columns={2}
            items={filteredSections}
            search={search}
            onToggle={(isExpanded: boolean, item) => {
              trackSegment('selectAccordion', {
                name: documentId,
                value: item.title,
                isExpanded,
              });
            }}
          />
        </ContentContainer>
      </Box>
    </Layout>
  );
};

export const toAccordionSection = (section: Section) => {
  return {
    title: section.title,
    terms: section.fields.map(toAccordionTerm),
    isExpanded: true,
  };
};

export const toAccordionTerm = (field: Field) => {
  return {
    title: field.name,
    description: field.displayValue,
    tooltip: field.help_text,
  };
};
