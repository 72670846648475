import { format, Locale } from 'date-fns';
import { noop } from 'lodash';
import React from 'react';

import { InputWidth } from '../../../types';
import { getDateTimeFormatPattern } from '../../../utils';
import { TextInput } from '../../TextInput';

interface Props {
  name: string;
  value: Date | null;
  locale?: Locale;
  placeholder?: string;
  width?: InputWidth;
}

/**
 * The trigger for DateInput is a read-only TextInput.
 *
 * This implementation follows Google's Material design for datepickers.
 */
export const InputTrigger = (props: Props) => {
  const { locale, placeholder, value } = props;

  const formatString = getDateTimeFormatPattern(locale?.code);
  const inputDateText = value ? format(value, formatString, { locale }) : '';

  return (
    <TextInput
      {...props}
      placeholder={placeholder || formatString.toLowerCase()}
      value={inputDateText}
      onChange={noop}
    />
  );
};
