//import { courier } from '~/services';

export const getEvents = async () => {
  //const response = await courier.get('/events');

  const data = [
    {
      options: [
        {
          label: 'Accordion 1',
          value: 'accordion 1',
        },
        {
          label: 'Accordion B',
          value: 'accordion b',
        },
        {
          label: 'Accordion 3',
          value: 'accordion 3',
        },
      ],
      label: 'Accordion A',
    },
    {
      options: [
        {
          label: 'Accordion A',
          value: 'accordion a',
        },
        {
          label: 'Accordion B',
          value: 'accordion b',
        },
        {
          label: 'Accordion C',
          value: 'accordion c',
        },
      ],
      label: 'Accordion B',
    },
    {
      options: [
        {
          label: 'Mexico A',
          value: 'mexico a',
        },
        {
          label: 'Peru B',
          value: 'peru b',
        },
        {
          label: 'USA C',
          value: 'usa c',
        },
        {
          label: 'Colombia D',
          value: 'colombia d',
        },
      ],
      label: 'Accordion C',
    },
    {
      options: [
        {
          label: 'Red',
          value: 'red',
        },
        {
          label: 'Blue',
          value: 'blue',
        },
        {
          label: 'Green',
          value: 'green',
        },
      ],
      label: 'Accordion D',
    },
  ];

  const mockedData = data.map((item) => ({
    options: item.options,
    name: item.label,
    label: item.label,
  }));

  return mockedData;
};
