import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { flattenFolderTree } from '~/components/SearchV2/SearchV2.utils';
import { Button, formatDate, formatNumber, Layout, Text, Tooltip } from '~/eds';
import { FieldType, OperatorId, operatorLabelsByFieldType } from '~/evifields';
import { api, selectors } from '~/redux';
import {
  DocumentGroup,
  FlattenFolderTree,
  FolderTree,
  SearchFilter,
  SectionEntity,
} from '~/types';

import { toSectionFiltersOperators } from '../QueryBuilderPanel/QueryBuilderPanel.utils';

type Props = {
  searchFilters: Record<string, SearchFilter>;
  onClear: () => void;
  onEdit: () => void;
};

const ReadOnlyComplexQuery = ({ searchFilters, onClear, onEdit }: Props) => {
  const {
    data: documentGroupsData,
  } = api.endpoints.getAllDocumentGroups.useQuery(undefined);
  const { data: folderTreeData } = api.endpoints.getFolderTree.useQuery(
    undefined,
  );
  const folders = folderTreeData?.children;
  const folderTree = useMemo(
    () => (!folders ? [] : flattenFolderTree(folders as FolderTree[])),
    [folders],
  );

  const crossFilters = useSelector(selectors.selectDashboardCrossFilters);
  const queryBuilder = useSelector(selectors.selectQueryBuilder);

  const queryFilters = toSectionFiltersOperators(
    queryBuilder as SectionEntity[],
    searchFilters,
  );

  const getValueByType = (opId: string, type: FieldType, value: any) => {
    switch (type) {
      case 'clause':
        if (value[0].text_search[0]) {
          const r = value[0].text_search[0];
          return `${value[0].provision} and ${r.scope.label} ${r.text}`;
        }
        return value[0].provision;
      case 'date':
        if (opId === 'date_in_the_last' || opId === 'date_in_the_next') {
          return `${value[0].value} ${value[0].unit}`;
        } else if (opId === 'is_blank') {
          return '';
        } else {
          return value.map((date: Date) => formatDate(date)).join(' - ');
        }
      case 'enum_set':
        return value.join(' - ');
      case 'number':
        const numericValue = value[0];
        if (numericValue === undefined) return value;
        return formatNumber(value);
      default:
        return value;
    }
  };

  const getValueByFieldTypeValue = (
    fieldId: string,
    opId: OperatorId,
    type: FieldType,
    values: [],
  ) => {
    switch (fieldId) {
      case 'document_group_id':
        const groupValues = getValueByType(opId, type, values).split(' - ');
        const groupNames = groupValues.map((item: string) => {
          const result = [];
          const group = documentGroupsData?.results.find(
            (grp: DocumentGroup) => item === grp.groupId.toString(),
          );
          if (group) {
            result.push(group.name);
          }
          return result;
        });
        return groupNames?.join(', ');
      case 'folder':
        const folderValues = getValueByType(opId, type, values);
        const folderNames = folderValues.map((item: string) => {
          const result = [];
          const folder = folderTree?.find(
            (folder: FlattenFolderTree) => item === folder.value,
          );
          if (folder) {
            result.push(folder.display_value);
          }
          return result;
        });
        return folderNames.join(', ');
      default:
        return getValueByType(opId, type, values);
    }
  };

  const generateFilterValue = (sectionItem: any) => {
    const filterOperator = sectionItem.value.operator?.value;
    const filters = sectionItem.value.filters;

    return filters.map((t: any, index: number) => {
      const type = searchFilters[t.fieldId].type;
      const opId = t.operatorId as OperatorId;
      const operatorValue = operatorLabelsByFieldType[type][opId];
      const value = getValueByFieldTypeValue(t.fieldId, opId, type, t.values);

      return (
        <Layout
          align="center"
          key={`${searchFilters[t.fieldId].label}-${t.operratorId}`}
        >
          <Tooltip
            tooltip={`${
              searchFilters[t.fieldId].label
            } ${operatorValue} ${value}`}
          >
            <Layout
              p={1}
              styles={{
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                borderRadius: '4px',
              }}
            >
              <Text variant="tiny">
                {searchFilters[t.fieldId.toString()].label}
              </Text>
            </Layout>
          </Tooltip>
          {index < filters.length - 1 ? (
            <Layout pl={3} pr={3}>
              <Text variant="tiny-bold">{filterOperator.toUpperCase()}</Text>
            </Layout>
          ) : null}
        </Layout>
      );
    });
  };

  const renderSectionEntity = (sectionItem: any, index: number) => {
    if (sectionItem.type === 'section') {
      return (
        <Layout key={`section_${index}`} align="center">
          <Layout pr={3}>(</Layout>
          {generateFilterValue(sectionItem)}
          <Layout pl={3}>)</Layout>
        </Layout>
      );
    } else {
      return (
        <Layout key={`item_${index}`} align="center">
          <Text variant="tiny-bold">{sectionItem.value.toUpperCase()}</Text>
        </Layout>
      );
    }
  };

  const queryMemoized = useMemo(() => queryFilters?.map(renderSectionEntity), [
    queryFilters,
  ]);

  return (
    <Layout align="center" spacing={3} wrap={true}>
      {queryMemoized}
      <Button
        disabled={!!crossFilters?.length}
        text="Edit Query"
        variant="action"
        onClick={onEdit}
      />
      <Button
        disabled={!!crossFilters?.length}
        text="Reset to Default Filters"
        variant="action"
        onClick={onClear}
      />
    </Layout>
  );
};

export default ReadOnlyComplexQuery;
