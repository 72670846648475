import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Info, Panel } from '~/eds';
import { slices } from '~/redux';

export const AttemptConfiguration = () => {
  const dispatch = useDispatch();

  const { activeAttempt, activeAttemptType: action } = useSelector(
    slices.webhooks.selectors.selectActiveAttempt,
  );

  if (!activeAttempt || action !== 'view') return null;

  return (
    <Panel
      actions={[
        {
          icon: 'x',
          text: 'Close',
          onClick: () => {
            dispatch(slices.webhooks.actions.resetAttemptConfiguration());
          },
        },
      ]}
      children={
        <Info
          sections={[
            {
              title: activeAttempt.endpointUrl,
              infos: [
                {
                  title: 'Event Type',
                  type: 'text' as const,
                  props: { text: activeAttempt.eventType },
                },
                {
                  title: 'Status',
                  type: 'chips' as const,
                  props: {
                    chips: [
                      {
                        status: 'success',
                        text: activeAttempt.status,
                      },
                    ],
                  },
                },
                {
                  title: 'Message ID',
                  type: 'text' as const,
                  props: {
                    text: activeAttempt.messageId,
                  },
                },
                {
                  title: 'Date Sent',
                  type: 'user-activity' as const,
                  props: {
                    activity: 'Date Sent',
                    date: activeAttempt.dateSent,
                    user: {
                      id: '1',
                      firstName: '',
                      lastName: '',
                      email: '',
                    },
                  },
                },
                {
                  title: 'Request Body Example',
                  type: 'textarea' as const,
                  props: {
                    name: 'requestBody',
                    value: JSON.stringify(activeAttempt.requestBody, null, 2),
                    readOnly: true,
                  },
                },
                {
                  title: 'Response Example',
                  type: 'textarea' as const,
                  props: {
                    name: 'responseExample',
                    value: JSON.stringify(
                      activeAttempt.responseExample,
                      null,
                      2,
                    ),
                    readOnly: true,
                  },
                },
              ],
            },
          ]}
        />
      }
      footer={{
        actions: [
          {
            text: 'Delete',
            level: 'tertiary',
            onClick: () => {
              dispatch(slices.webhooks.actions.setActiveAttemptType('delete'));
            },
          },
          {
            text: 'Resend',
            onClick: () => {
              dispatch(slices.webhooks.actions.setActiveAttemptType('resend'));
            },
          },
        ],
      }}
      hidden={{
        isHidden: false,
        onHide: () => {
          dispatch(slices.webhooks.actions.resetAttemptConfiguration());
        },
      }}
      position="right"
      title="Attempt Configuration"
      width="m"
    />
  );
};
