//import { courier } from '~/services';
import { Nullable } from '~/types';

interface Request {
  selectedEvent: Nullable<string>;
}

export const getEventSamplePayload = async ({
  selectedEvent,
}: Request): Promise<any> => {
  if (!selectedEvent) {
    return null;
  }

  //const response = await courier.get(`/event-sample/${selectedEvent}`);

  //TODO: API will return an object where keys are selected event types, and the value is the sample payload.

  const mockedata = {
    'accordion a': {
      'string-1': 'string',
      'number-1': '1',
    },
    'accordion b': {
      'string-2': 'string',
      'number-2': '2',
    },
    'accordion c': {
      'string-3': 'string',
      'number-3': '3',
    },
    'peru b': {
      'string-4': 'string',
      'number-4': '4',
    },
    'mexico a': {
      'string-5': 'string',
      'number-5': '5',
    },
    'colombia d': {
      'string-6': 'string',
      'number-6': '6',
    },
    red: {
      'string-7': 'string',
      'number-7': '7',
    },
    blue: {
      'string-8': 'string',
      'number-8': '8',
    },
  };

  const mockedResponse = mockedata[selectedEvent as keyof typeof mockedata];

  return JSON.stringify(mockedResponse, null, 2);
};
