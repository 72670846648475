import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import { black1 } from '~/assets/shared-styles/general';
import AdminConsolePageNavHeader from '~/components/Admin/AdminConsolePageNavHeader';
import {
  deleteParty,
  getExternalPartyList,
  getInternalPartyList,
  styles,
  updatePartyList,
} from '~/components/Admin/PartyListsPage';
import { trackSegment } from '~/components/SegmentAnalytics';
import EcCard from '~/components/Shared/EcCard';
import EcModal from '~/components/Shared/EcModal';
import EcPaginate from '~/components/Shared/EcPaginate';
import EcPartyTable from '~/components/Shared/EcPartyTable';
import { showToast } from '~/components/Shared/EcToast';
import LoadingSpinner from '~/components/Shared/Icons/LoadingSpinner';
import PlusIcon from '~/components/Shared/Icons/PlusIcon';
import { withRouting } from '~/routing';
import { uploadDocuments } from '~/slices/fileUpload';
import {
  MODAL_CLIENT_PARTY,
  MODAL_DELETE,
  MODAL_UPLOAD,
} from '~/types/modal.types';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { getClientInfo, getUserClientInfo } from '~/utils/user';

const PAGE_SIZE = 10;

class Page extends Component {
  constructor(props) {
    super(props);

    this.navigateBack = this.navigateBack.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleAddUploadFiles = this.handleAddUploadFiles.bind(this);
    this.handleRemoveUploadFile = this.handleRemoveUploadFile.bind(this);
    this.onPartyCreate = this.onPartyCreate.bind(this);
    this.onPartyUpdate = this.onPartyUpdate.bind(this);
    this.onPartyDelete = this.onPartyDelete.bind(this);

    this.state = {
      currentModal: null,
      errorMessage: '',
      externalPage: 1,
      externalPageCount: 1,
      externalPartyList: [],
      externalPartyListCount: 0,
      hasErrorLoadingExternalPartyList: false,
      hasErrorLoadingInternalPartyList: false,
      internalPage: 1,
      internalPageCount: 1,
      internalPartyList: [],
      internalPartyListCount: 0,
      isInternal: false,
      isLoadingExternalTable: false,
      isLoadingInternalTable: false,
      newPartyData: { name: '' },
      selectedParty: null,
      uploadFiles: [],
    };
  }

  componentDidMount() {
    this.loadInternalPartyList();
    this.loadExternalPartyList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentModal === MODAL_UPLOAD && !this.state.currentModal) {
      this.setState({ uploadFiles: [] });
    }
  }

  navigateBack() {
    const { navigate } = this.props;

    navigate(-1);
  }

  loadExternalPartyList() {
    const { externalPage, externalPartyListCount } = this.state;
    this.setState({ isLoadingExternalTable: true });
    const {
      params: { clientId },
    } = this.props;

    const totalPages = Math.ceil(externalPartyListCount / PAGE_SIZE);
    const pageToFetch =
      externalPage > totalPages && externalPage >= 2
        ? externalPage - 1
        : externalPage;
    return getExternalPartyList(clientId, pageToFetch)
      .then(({ results, count }) => {
        this.setState({
          externalPage: pageToFetch,
          externalPartyList: results,
          externalPartyListCount: count,
          isLoadingExternalTable: false,
          hasErrorLoadingExternalPartyList: false,
        });
        // return this so the dropzone chunksUploaded logic works
        return new Promise((resolve) => {
          resolve();
        });
      })
      .catch(() => {
        this.setState({
          isLoadingExternalTable: false,
          hasErrorLoadingExternalPartyList: true,
        });
      });
  }

  loadInternalPartyList() {
    const { internalPage, internalPartyListCount } = this.state;
    this.setState({ isLoadingInternalTable: true });
    const {
      params: { clientId },
    } = this.props;

    const totalPages = Math.ceil(internalPartyListCount / PAGE_SIZE);
    const pageToFetch =
      internalPage > totalPages && internalPage >= 2
        ? internalPage - 1
        : internalPage;
    return getInternalPartyList(clientId, pageToFetch)
      .then(({ results, count }) => {
        this.setState({
          internalPage: pageToFetch,
          internalPartyList: results,
          internalPartyListCount: count,
          isLoadingInternalTable: false,
          hasErrorLoadingInternalPartyList: false,
        });
        // return this so the dropzone chunksUploaded logic works
        return new Promise((resolve) => {
          resolve();
        });
      })
      .catch(() => {
        this.setState({
          isLoadingInternalTable: false,
          hasErrorLoadingInternalPartyList: true,
        });
      });
  }

  handleAddUploadFiles(files, uploadCb) {
    const newFiles = Object.keys(files).map((key) => {
      const file = files[key];
      file.uploadCb = uploadCb;
      return file;
    });
    this.setState({ uploadFiles: [...this.state.uploadFiles, ...newFiles] });
  }

  handleRemoveUploadFile(uploadId) {
    const newStateFiles = this.state.uploadFiles.filter(
      (file) => file.upload.uuid !== uploadId,
    );
    this.setState({ uploadFiles: newStateFiles });
  }

  handlePageClick(clickedPage, isInternal) {
    const selectedPage = clickedPage.selected + 1;

    const attribute = isInternal ? 'internalPage' : 'externalPage';
    const loadPartyList = isInternal
      ? this.loadInternalPartyList
      : this.loadExternalPartyList;
    this.setState({ [attribute]: selectedPage }, loadPartyList);
  }

  handleUpdatePartyError(errorMessage, name = '') {
    const newPartyData = { name };

    this.setState({ errorMessage, newPartyData });
  }

  handleShowModal(modal, selectedParty = null) {
    this.setState({
      currentModal: modal,
      selectedParty: selectedParty,
      newPartyData: {
        name: '',
      },
    });
  }

  handleHideModal() {
    this.setState({
      currentModal: null,
      selectedParty: null,
      newPartyData: {
        name: '',
      },
      errorMessage: '',
    });
  }

  handleShowToast(type, text) {
    showToast(type, text);
  }

  onPartyCreate(name) {
    const {
      externalPartyListCount,
      internalPartyListCount,
      isInternal,
    } = this.state;
    const {
      params: { clientId },
    } = this.props;

    const id = isInternal
      ? internalPartyListCount + 1
      : externalPartyListCount + 1;
    const newParty = { id, name };

    updatePartyList(clientId, isInternal, newParty)
      .then(() => {
        const loadPartyList = isInternal
          ? this.loadInternalPartyList
          : this.loadExternalPartyList;
        this.setState(
          {
            errorMessage: '',
            newPartyData: newParty,
          },
          loadPartyList,
        );
        this.handleHideModal();
        this.handleShowToast(
          SUCCESS,
          `${isInternal ? 'Internal' : 'External'} party "${
            newParty.name
          }" has been added to this client.`,
        );
      })
      .catch((err) => {
        if (err.response.data.detail) {
          this.handleUpdatePartyError(err.response.data.detail, name);
        } else {
          this.handleUpdatePartyError(
            'Please check the values you entered.',
            name,
          );
        }
      });
  }

  onPartyDelete() {
    const {
      params: { clientId },
    } = this.props;
    const {
      externalPartyListCount,
      isInternal,
      internalPartyListCount,
      selectedParty,
    } = this.state;

    deleteParty(clientId, isInternal, selectedParty)
      .then(() => {
        this.handleShowToast(
          SUCCESS,
          `Party "${selectedParty.name}" has been removed from the list.`,
        );
        const loadPartyList = isInternal
          ? this.loadInternalPartyList
          : this.loadExternalPartyList;
        this.setState(
          {
            errorMessage: '',
            externalPartyListCount: isInternal
              ? externalPartyListCount
              : externalPartyListCount - 1,
            internalPartyListCount: isInternal
              ? internalPartyListCount - 1
              : internalPartyListCount,
            selectedParty: null,
          },
          loadPartyList,
        );
      })
      .catch((err) => this.handleShowToast(ERROR, err.message));
  }

  onPartyUpdate(name) {
    const { isInternal, selectedParty } = this.state;
    const {
      params: { clientId },
    } = this.props;
    const newParty = { ...selectedParty, name };
    updatePartyList(clientId, isInternal, newParty, selectedParty.name)
      .then(() => {
        const loadPartyList = isInternal
          ? this.loadInternalPartyList
          : this.loadExternalPartyList;
        this.setState(
          {
            errorMessage: '',
            newPartyData: newParty,
            selectedParty: null,
          },
          loadPartyList,
        );
        this.handleHideModal();
        this.handleShowToast(
          SUCCESS,
          `Party "${selectedParty.name}" has been renamed to "${newParty.name}".`,
        );
      })
      .catch((err) => {
        if (err.response.data.detail) {
          this.handleUpdatePartyError(err.response.data.detail, name);
        } else {
          this.handleUpdatePartyError(
            'Please check the values you entered.',
            name,
          );
        }
      });
  }
  renderDeleteModalText() {
    const { selectedParty } = this.state;

    return (
      <Fragment>
        Are you sure you want to delete <span>{selectedParty.name}</span>? You
        won’t be able to undo this action.
      </Fragment>
    );
  }

  renderDeleteModal() {
    return (
      <EcModal
        modalType={MODAL_DELETE}
        width="560px"
        title="Delete Selected Party?"
        text={this.renderDeleteModalText()}
        confirmButtonText="Delete"
        deleteItem={this.onPartyDelete}
        hideModal={this.handleHideModal}
      />
    );
  }

  renderPartyModal() {
    const { selectedParty, newPartyData, errorMessage } = this.state;

    return (
      <EcModal
        modalType={MODAL_CLIENT_PARTY}
        width="560px"
        title={selectedParty ? 'Edit Party' : 'New Party'}
        confirmButtonIcon={
          selectedParty ? null : <PlusIcon size="20" color={black1} />
        }
        confirmButtonText={selectedParty ? 'Save' : 'Add Party'}
        name={selectedParty ? selectedParty.name : newPartyData.name}
        handleNameChange={
          selectedParty ? this.onPartyUpdate : this.onPartyCreate
        }
        hideModal={this.handleHideModal}
        handleShowModal={this.handleShowModal}
        errorMessage={errorMessage}
      />
    );
  }

  renderUploadModal() {
    const { isInternal, uploadFiles } = this.state;
    const { uploadDocuments, currentUser } = this.props;
    const {
      params: { clientId },
    } = this.props;

    const loadInternalPartyList = this.loadInternalPartyList.bind(this);
    const loadExternalPartyList = this.loadExternalPartyList.bind(this);
    const addUploadFiles = this.handleAddUploadFiles.bind(this);
    const handleAddUploadFiles = (files) => {
      const loadPartyList = isInternal
        ? loadInternalPartyList
        : loadExternalPartyList;
      addUploadFiles(files, loadPartyList);
    };

    const handleUploadFiles = (formFields) => {
      formFields.push({ isInternal });
      trackSegment('User Uploads Document', {
        fileNames: uploadFiles.map((file) => file.name),
        groupId: getClientInfo(currentUser),
        userId: getUserClientInfo(currentUser),
      });
      uploadDocuments({
        files: uploadFiles,
        uploadFormFields: formFields,
        apiConfig: {
          service: 'pilot',
          method: 'post',
          url: `/client/${clientId}/upload-party-list/`,
        },
      });
      this.setState({
        errorMessage: '',
        selectedParty: null,
      });
    };

    const docsSectionStyles = { width: '100%', paddingLeft: 0 };

    return (
      <EcModal
        key="primary-modal"
        modalType={MODAL_UPLOAD}
        width="755px"
        docsSectionStyles={docsSectionStyles}
        title="Upload"
        hasUploadPermission={true}
        uploadFiles={uploadFiles}
        uploadLocationPath={[]}
        confirmButtonText="Upload Party List"
        docsSectionTitle="Party Lists"
        displayInfoSection={false}
        handleAddUploadFiles={handleAddUploadFiles}
        handleRemoveUploadFile={this.handleRemoveUploadFile}
        handleUploadFiles={handleUploadFiles}
        hideModal={this.handleHideModal}
      />
    );
  }

  renderPagination(isInternal) {
    const { classes } = this.props;
    const {
      externalPage,
      externalPartyListCount,
      internalPage,
      internalPartyListCount,
    } = this.state;
    let partyListCount, page;

    if (isInternal) {
      partyListCount = internalPartyListCount;
      page = internalPage;
    } else {
      partyListCount = externalPartyListCount;
      page = externalPage;
    }

    return partyListCount > PAGE_SIZE ? (
      <div className={classes.paginationWrapper}>
        <EcPaginate
          onPageChange={(page) => this.handlePageClick(page, isInternal)}
          pageCount={Math.ceil(partyListCount / PAGE_SIZE)}
          forcePage={page - 1}
        />
      </div>
    ) : null;
  }

  renderPartyListsContent() {
    const { classes } = this.props;
    const { externalPartyList, internalPartyList, loadingTable } = this.state;

    const setInternal = () => this.setState({ isInternal: true });
    const setExternal = () => this.setState({ isInternal: false });

    const styles = {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 16px',
    };
    return (
      <Fragment>
        <AdminConsolePageNavHeader
          title="Manage Party Lists"
          navigateBack={this.navigateBack}
        />
        <EcCard contentStyles={styles}>
          {loadingTable ? (
            <div className={classes.loadingContainer}>
              <LoadingSpinner size="medium" />
            </div>
          ) : (
            <EcPartyTable
              fileUploadButtonId="uploadInternalPartyList"
              handleShowModal={this.handleShowModal}
              listItems={internalPartyList}
              setInternal={setInternal}
              title="Internal Parties"
            />
          )}
          <div
            id="internalPagination"
            className={classes.bottomPaginationWrapper}
          >
            {' '}
            {this.renderPagination(true)}
          </div>
        </EcCard>
        <div className={classes.tableSeparator} />
        <EcCard contentStyles={styles}>
          {loadingTable ? (
            <div className={classes.loadingContainer}>
              <LoadingSpinner size="medium" />
            </div>
          ) : (
            <EcPartyTable
              fileUploadButtonId="uploadExternalPartyList"
              handleShowModal={this.handleShowModal}
              listItems={externalPartyList}
              setInternal={setExternal}
              title="Counterparties"
            />
          )}
          <div
            id="externalPagination"
            className={classes.bottomPaginationWrapper}
          >
            {' '}
            {this.renderPagination(false)}
          </div>
        </EcCard>
      </Fragment>
    );
  }

  renderErrorLoadingPartyLists() {
    return (
      <div>There was an error loading the party lists for this client.</div>
    );
  }

  render() {
    const { classes } = this.props;
    const {
      currentModal,
      errorLoadingPartyLists,
      isInternal,
      loading,
    } = this.state;

    return (
      <div
        className={classes.adminUsersTabContent}
        data-testid="party-list-tab"
      >
        {loading ? (
          <div className={classes.loadingContainer}>
            <LoadingSpinner size="medium" />
          </div>
        ) : errorLoadingPartyLists ? (
          this.renderErrorLoadingPartyLists()
        ) : (
          this.renderPartyListsContent()
        )}
        {currentModal === MODAL_CLIENT_PARTY
          ? this.renderPartyModal(isInternal)
          : null}
        {currentModal === MODAL_DELETE
          ? this.renderDeleteModal(isInternal)
          : null}
        {currentModal === MODAL_UPLOAD
          ? this.renderUploadModal(isInternal)
          : null}
      </div>
    );
  }
}

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ currentUser }) => ({ currentUser });

Page = connect(mapStateToProps, { uploadDocuments })(Page);

export default injectSheet(styles)(withRouting(Page));
