import { Endpoint } from '~/features/webhooks';
import { courier } from '~/services';
import { Uuid } from '~/types';

interface Response {
  id: Uuid;
  url: string;
  name: string;
  description: string;
  events: string[];
  secret: string;
  user: any;
}

export const getEndpoints = async (): Promise<Endpoint[]> => {
  const response: Response[] = await courier.get('/endpoints');

  return mapToResponse(response);
};

const mapToResponse = (response: Response[]): Endpoint[] => {
  return response.map((endpoint: Response) => ({
    ...endpoint,
    selectedEvents: endpoint.events,
    endpointUrl: endpoint.url,
    id: endpoint.id,
    secret: endpoint.secret,
    // TODO: Missing properties from  API response
    status: 'Active',
    dateCreated: new Date(),
    dateModified: new Date(),
    user: {
      firstName: 'John',
      lastName: 'Doe',
    },
  }));
};
