import React, { useState } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import EcFilterInput from '~/components/Shared/EcFilterInput';
import EcTooltip from '~/components/Shared/EcTooltip';
import { Icon } from '~/eds';
import { Box, FlexLayout, Link, Text } from '~/ui';
import { copyToClipboard } from '~/utils/helper.utils';

import styles from './WorkflowTemplatesList.styles';

const WorkflowTemplatesList = ({ classes, currentUser, templatesData }) => {
  const [copiedTooltip, showCopiedTooltip] = useState(false);

  const [filterValue, setFilterValue] = useState('');

  const filteredItems = templatesData.filter((item) =>
    item.name.toLowerCase().includes(filterValue.toLowerCase()),
  );

  function handleOnCopyAction(item) {
    showCopiedTooltip(true);
    const linkConstructed = `${window.location.origin}/${currentUser.client_config.sub_domain}/workflow/${item.permanentLinkId}/intake-form/link`;
    copyToClipboard(linkConstructed, null, null, handleCopiedTooltip);
  }

  function handleCopiedTooltip() {
    setTimeout(() => {
      showCopiedTooltip(false);
    }, 2000);
  }

  return (
    <FlexLayout alignItems="center" flexDirection="column" space={6}>
      <Box className={classes.templatesFilter}>
        <EcFilterInput
          autoFocus
          value={filterValue}
          onChange={(filterValue) => setFilterValue(filterValue)}
          onClearSearch={() => setFilterValue('')}
          placeholder="Search workflows..."
        />
      </Box>
      <Box className={classes.templatesList}>
        {filteredItems.length ? (
          filteredItems.map((item) => (
            <FlexLayout
              key={`workflow-${item.id}`}
              alignItems="center"
              className={classes.listItem}
              justifyContent="space-between"
              p={4}
              space={4}
            >
              <FlexLayout flexDirection="column" sx={{ maxWidth: '50%' }}>
                <Link to={`/workflow/${item.permanentLinkId}/intake-form/link`}>
                  {item.name}
                </Link>
                {item.description && (
                  <Text
                    color="gray-600"
                    variant="xs-dense"
                    sx={{ wordBreak: 'break-word' }}
                  >
                    {item.description}
                  </Text>
                )}
              </FlexLayout>
              <FlexLayout alignItems="center" p={4} space={4}>
                <FlexLayout pl={4}>
                  <span data-tip data-for={item.permanentLinkId}>
                    <button
                      className={classes.linkIconButton}
                      onClick={() => handleOnCopyAction(item)}
                    >
                      <Icon icon="link" size="s" />
                    </button>
                  </span>
                  <EcTooltip id={item.permanentLinkId}>
                    {copiedTooltip
                      ? 'Intake Form Link copied'
                      : 'Copy Intake Form Link'}
                  </EcTooltip>
                </FlexLayout>
              </FlexLayout>
            </FlexLayout>
          ))
        ) : (
          <Box p={6}>
            <Text color="gray-600" variant="xs-dense">
              No workflow templates match your search.
            </Text>
          </Box>
        )}
      </Box>
    </FlexLayout>
  );
};

const mapStateToProps = ({ currentUser }) => ({ currentUser });

export default injectSheet(styles)(
  connect(mapStateToProps)(WorkflowTemplatesList),
);
