import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ContentContainer, InfoCard, Tabs } from '~/eds';
import { api, slices } from '~/redux';

import { AttemptConfiguration } from './AttemptConfiguration';
import { AttemptModal } from './AttemptModal';
import { Attempts } from './Attempts';
import { EndpointConfiguration } from './EndpointConfiguration';
import { EndpointModal } from './EndpointModal';
import { Endpoints } from './Endpoints';
import { TabType } from './types';

export const Webhooks = () => {
  const dispatch = useDispatch();
  const tab = useSelector(slices.webhooks.selectors.selectTab);

  const {
    data: results = [],
    isLoading: isLoadingEndpoints,
  } = api.endpoints.getEndpoints.useQuery(undefined);

  return (
    <>
      <ContentContainer loadingContent={{ isLoading: isLoadingEndpoints }}>
        {results.length > 0 ? (
          <Tabs<TabType>
            actions={
              tab === 'endpoints'
                ? [
                    {
                      text: 'Add Endpoint',
                      level: 'primary',
                      onClick: () => {
                        dispatch(
                          slices.webhooks.actions.setActiveEndpointType('add'),
                        );
                      },
                    },
                  ]
                : []
            }
            tabs={[
              {
                label: 'Endpoints',
                value: 'endpoints',
                panel: (
                  <Endpoints
                    endpoints={results}
                    isLoading={isLoadingEndpoints}
                  />
                ),
              },
              {
                label: 'Attempts',
                value: 'attempts',
                panel: <Attempts />,
              },
            ]}
            selectedTab={tab}
            onSelectTab={(tab) => dispatch(slices.webhooks.actions.setTab(tab))}
          />
        ) : (
          <InfoCard
            action={{
              level: 'primary',
              onClick: () =>
                dispatch(slices.webhooks.actions.setActiveEndpointType('add')),
              text: 'Add Endpoint',
            }}
            description="Create webhook endpoints to listen for Evisort events, allowing your integration to receive notifications when asynchronous actions take place."
            title="Create Endpoints for Evisort Events"
          />
        )}
      </ContentContainer>
      <EndpointConfiguration />
      <AttemptConfiguration />
      <EndpointModal />
      <AttemptModal />
    </>
  );
};
