import { TicketStageType } from '~/enums';

export const StageConfig = {
  [TicketStageType.Edit]: {
    label: 'Edit',
    value: TicketStageType.Edit,
    order: 0,
  },
  [TicketStageType.Review]: {
    label: 'Review',
    value: TicketStageType.Review,
    order: 1,
  },
  [TicketStageType.Sign]: {
    label: 'Sign',
    value: TicketStageType.Sign,
    order: 2,
  },
  [TicketStageType.Finalize]: {
    label: 'Finalize',
    value: TicketStageType.Finalize,
    order: 3,
  },
  [TicketStageType.Ticket]: {
    label: 'Ticket',
    value: TicketStageType.Ticket,
    order: 100,
  },
};

export const TICKET_DOCUMENT_VERSION_EDITOR_KEY_POLLING_INTERVAL = 10000;
export const TICKET_DOCUMENT_VERSION_POLLING_MAX_COUNTER = 3;

export const WORKFLOW_TAB_INDEX = 0;
export const FORM_INFO_TAB_INDEX = 1;
