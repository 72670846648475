import React, { useMemo } from 'react';

import {
  Box,
  ContentContainer,
  INPUT_DEBOUNCE_MS,
  Layout,
  SearchInput,
  testHasTextMatch,
  useThrottledState,
} from '~/eds';
import { FeatureFlagType } from '~/enums';
import { useCurrentUser } from '~/hooks';
import { testHasFlag } from '~/permissions';
import { api } from '~/redux';
import { PilotId } from '~/types';

import { ClausesAccordion } from '../../ClausesAccordion';

interface Props {
  docHandlerId?: PilotId;
  featuredClauses?: string[];
}

export const Clauses = ({ docHandlerId, featuredClauses }: Props) => {
  const [throttledSearch, search, handleSearch] = useThrottledState(
    '',
    INPUT_DEBOUNCE_MS,
  );
  const currentUser = useCurrentUser();
  const hasEditClauseFlag = testHasFlag(FeatureFlagType.EditClause)(
    currentUser,
  );
  const endpoint = hasEditClauseFlag
    ? 'getAllProvisionsGrouped'
    : 'getDocumentDetailClauses';

  const { data = [], isFetching, isError } = api.endpoints[
    endpoint
  ].useQueryState(
    hasEditClauseFlag
      ? { docId: docHandlerId!, page: 1, pageSize: 100 }
      : ({ documentId: docHandlerId! } as any),
    {
      skip: !docHandlerId,
    },
  );

  const filteredClauses = useMemo(
    () =>
      throttledSearch.length
        ? data?.filter((clause) => testHasTextMatch(clause.name, search))
        : data,
    [data, throttledSearch],
  );

  const loadingContent = { isLoading: isFetching, message: 'Loading clauses' };

  const placeholderContent = isError
    ? {
        icon: 'field-clause' as const,
        message:
          'Something went wrong when retrieving the clauses. Please refresh and load again or contact your admin.',
      }
    : !data.length
    ? {
        title: 'No Clauses found.',
        message: 'No clauses were found for this file.',
        image: 'empty-no-search',
      }
    : undefined;

  const searchPlaceholderContent =
    search && !filteredClauses.length
      ? { title: 'No matching clauses', image: 'empty-no-search' }
      : undefined;

  return (
    <ContentContainer
      loadingContent={loadingContent}
      placeholderContent={placeholderContent}
    >
      <Layout h="100%" direction="column">
        <Box p={4}>
          <SearchInput
            disabled={isError || isFetching}
            placeholder="Search clauses"
            name="search"
            onChange={(text) => handleSearch(text ?? '')}
            value={search}
          />
        </Box>
        <Box overflowY="auto">
          <ContentContainer placeholderContent={searchPlaceholderContent}>
            <>
              <ClausesAccordion
                featuredClauses={featuredClauses}
                search={throttledSearch}
                clauses={filteredClauses as any}
              />
            </>
          </ContentContainer>
        </Box>
      </Layout>
    </ContentContainer>
  );
};

export default Clauses;
