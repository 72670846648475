import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  CopyButton,
  Info,
  Layout,
  Panel,
  Text,
  useToggle,
} from '~/eds';
import { slices } from '~/redux';

export const EndpointConfiguration = () => {
  const dispatch = useDispatch();

  const { activeEndpoint, activeEndpointType: action } = useSelector(
    slices.webhooks.selectors.selectActiveEndpoint,
  );

  const [isKeyVisible, _toggle, showKey, hideKey] = useToggle();

  if (!activeEndpoint || action !== 'view') return null;

  return (
    <Panel
      actions={[
        {
          icon: 'x',
          text: 'Close',
          onClick: () => {
            dispatch(slices.webhooks.actions.resetEndpointConfiguration());
            hideKey();
          },
        },
      ]}
      children={
        <Info
          sections={[
            {
              title: activeEndpoint.endpointUrl,
              infos: [
                {
                  title: 'Status',
                  type: 'text' as const,
                  props: { text: activeEndpoint.status },
                },
                {
                  title: 'Description',
                  type: 'text' as const,
                  props: { text: activeEndpoint.description },
                },
                {
                  title: 'Selected Events',
                  type: 'text' as const,
                  props: {
                    text: activeEndpoint.selectedEvents.join(', ').toString(),
                  },
                },
                {
                  title: 'Created',
                  type: 'user-activity' as const,
                  props: {
                    activity: 'Created',
                    date: activeEndpoint.dateCreated,
                    user: {
                      id: 1,
                      firstName: activeEndpoint.user.firstName ?? '',
                      lastName: activeEndpoint.user.lastName ?? '',
                      email: '',
                    },
                  },
                },
                {
                  title: 'Modified',
                  type: 'user-activity' as const,
                  props: {
                    activity: 'Modified',
                    date: activeEndpoint.dateModified,
                    user: {
                      id: 2,
                      firstName: activeEndpoint.user.firstName ?? '',
                      lastName: activeEndpoint.user.lastName ?? '',
                      email: '',
                    },
                  },
                },
                {
                  title: 'Secure Signing',
                  type: 'content' as const,
                  props: {
                    children: (
                      <>
                        {isKeyVisible ? (
                          <Layout direction="column">
                            <Layout preset="buttons">
                              <Text>{activeEndpoint.secret}</Text>
                              <CopyButton
                                copyText={activeEndpoint.secret}
                                disableText
                                size="s"
                              />
                            </Layout>
                            <Layout
                              styles={{
                                '> button': {
                                  padding: 0,
                                },
                              }}
                            >
                              <Button
                                text="Hide"
                                onClick={hideKey}
                                variant="action"
                              />
                            </Layout>
                          </Layout>
                        ) : (
                          <Layout
                            styles={{
                              '> button': {
                                padding: 0,
                              },
                            }}
                          >
                            <Button
                              text="Reveal Key"
                              onClick={showKey}
                              variant="action"
                            />
                          </Layout>
                        )}
                      </>
                    ),
                  },
                },
                {
                  title: '',
                  type: 'link' as const,
                  props: {
                    text: 'See all attempts',
                    onClick: () => {
                      dispatch(slices.webhooks.actions.setTab('attempts'));
                    },
                  },
                },
              ],
            },
          ]}
        />
      }
      footer={{
        actions: [
          {
            text: 'Delete',
            level: 'tertiary',
            onClick: () => {
              dispatch(slices.webhooks.actions.setActiveEndpointType('delete'));
            },
          },
          {
            text: 'Suspend',
            onClick: () => {
              dispatch(
                slices.webhooks.actions.setActiveEndpointType('suspend'),
              );
            },
          },
          {
            text: 'Edit',
            onClick: () => {
              dispatch(slices.webhooks.actions.setActiveEndpointType('edit'));
            },
          },
        ],
      }}
      hidden={{
        isHidden: false,
        onHide: () => {
          dispatch(slices.webhooks.actions.resetEndpointConfiguration());
        },
      }}
      position="right"
      title="Endpoint Configuration"
      width="m"
    />
  );
};
