import { useDispatch } from 'react-redux';

import { Layout, Table } from '~/eds';
import { api, slices } from '~/redux';

import { Attempt } from './types';
import { getStatusChip } from './utils';

export const Attempts = () => {
  const dispatch = useDispatch();

  const {
    data: attempts = [],
    isLoading: isLoadingAttempts,
  } = api.endpoints.getAttempts.useQuery(undefined);

  const columns = [
    {
      key: 'endpointUrl',
      title: 'Endpoint URL',
      cellType: 'text',
      mapCellProps: (attempt: Attempt) => ({
        text: attempt.endpointUrl,
      }),
    },
    {
      key: 'eventType',
      title: 'Event Type',
      cellType: 'text',
      mapCellProps: (attempt: Attempt) => ({
        text: attempt.eventType,
      }),
    },
    {
      key: 'status',
      title: 'Status',
      cellType: 'chips',
      mapCellProps: (attempt: Attempt) => {
        const { text, status } = getStatusChip(attempt.status);
        return {
          chips: [
            {
              text,
              status,
            },
          ],
        };
      },
    },
    {
      key: 'messageId',
      title: 'Message ID',
      cellType: 'text',
      mapCellProps: (attempt: Attempt) => ({
        text: attempt.messageId,
      }),
    },
    {
      key: 'dateSent',
      title: 'Date Sent',
      cellType: 'datetime',
      mapCellProps: (attempt: Attempt) => ({
        datetime: attempt.dateSent,
        format: 'full',
      }),
      width: 'm',
    },
  ];

  return (
    <Layout preset="sections">
      <Table
        data={attempts}
        columns={columns}
        isLoading={isLoadingAttempts}
        options={{
          enableManageColumns: false,
          enableSelectRows: false,
        }}
        rowActions={[
          {
            label: 'View Details',
            onClick: (attempt: Attempt) => {
              dispatch(slices.webhooks.actions.setActiveAttempt(attempt));
              dispatch(slices.webhooks.actions.setActiveAttemptType('view'));
            },
          },
          {
            label: 'Resend',
            onClick: (attempt: Attempt) => {
              dispatch(slices.webhooks.actions.setActiveAttempt(attempt));
              dispatch(slices.webhooks.actions.setActiveAttemptType('resend'));
            },
          },
          {
            label: 'Delete',
            onClick: (attempt: Attempt) => {
              dispatch(slices.webhooks.actions.setActiveAttempt(attempt));
              dispatch(slices.webhooks.actions.setActiveAttemptType('delete'));
            },
          },
        ]}
      />
    </Layout>
  );
};
