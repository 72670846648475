import React from 'react';
import { DayPicker as ReactDayPicker } from 'react-day-picker';
import 'react-day-picker/style.css';

import { Input } from '../../../types';
import { Box } from '../../Box';
import { ContentContainer } from '../../ContentContainer';
import { Dropdown, DropdownBody } from '../../Dropdown';
import { Layout } from '../../Layout';
import { CommonProps, DateRange } from '../types';
import { Caption } from './Caption';
import { Day } from './Day';
import * as formatters from './formatters';
import { InputTrigger } from './InputTrigger';
import { getDisabledMatcher, getMonth } from './utils';
import { ViewProvider } from './ViewContext';

const components = {
  Caption,
  Day,
};

type Rangible<R extends boolean> = R extends true
  ? Input<DateRange>
  : Input<Date>;

type Props<R extends boolean> = Rangible<R> &
  CommonProps & {
    isLoading?: R;
    isRange?: R;
  };

export const DayPicker = <R extends boolean>({
  disabled,
  disabledDays,
  disabledDates,
  enableControl = true,
  error,
  id,
  isEmbedded,
  isLoading,
  isRange,
  locale,
  max,
  min,
  month,
  name,
  readOnly,
  value,
  width,
  onChange,
}: Props<R>) => {
  const handleChange = (updatedDate: Date & DateRange) => {
    onChange(updatedDate || null);
  };

  const dayPicker = (
    <ViewProvider>
      <Box readOnly={readOnly}>
        <ReactDayPicker
          disabled={getDisabledMatcher({
            disabled,
            disabledDays,
            disabledDates,
            max,
            min,
          })}
          components={components}
          defaultMonth={getMonth(value, isRange)}
          formatters={formatters}
          locale={locale}
          mode={isRange ? 'range' : 'single'}
          month={month}
          selected={value || undefined}
          // @ts-ignore: Vendor type bug.  onSelect is always part of the public API
          onSelect={handleChange}
        />
      </Box>
    </ViewProvider>
  );

  const triggerProps = {
    disabled,
    id,
    name,
    error,
    width,
  };

  let control;
  if (isRange) {
    control = (
      <Layout spacing={2}>
        <InputTrigger
          {...triggerProps}
          name={`${name}-start`}
          placeholder="Start Date"
          value={(value as DateRange)?.from || null}
          width="50%"
        />
        <InputTrigger
          {...triggerProps}
          name={`${name}-end`}
          placeholder="End Date"
          value={(value as DateRange)?.to || null}
          width="50%"
        />
      </Layout>
    );
  } else {
    control = <InputTrigger {...triggerProps} value={value as Date} />;
  }

  if (isEmbedded) {
    return enableControl ? (
      <Layout direction="column" spacing={4} w="min-content">
        {control}
        {dayPicker}
      </Layout>
    ) : (
      dayPicker
    );
  }

  if (disabled || readOnly) {
    return control;
  }

  return (
    <Dropdown trigger={control}>
      <DropdownBody>
        <ContentContainer loadingContent={{ isLoading }}>
          {dayPicker}
        </ContentContainer>
      </DropdownBody>
    </Dropdown>
  );
};
