import { api } from '../createApi';
import { TagType } from '../TagType';

export const TAG_WORKFLOWS_BY_LIST = { type: TagType.Workflows, id: 'LIST' };

api.enhanceEndpoints({
  addTagTypes: [TagType.Workflows as TagType],
  endpoints: {
    deleteWorkflow: {
      invalidatesTags: [TAG_WORKFLOWS_BY_LIST],
    },
    getWorkflows: {
      providesTags: [TAG_WORKFLOWS_BY_LIST],
    },
    publishWorkflow: {
      invalidatesTags: [TAG_WORKFLOWS_BY_LIST],
    },
    unpublishWorkflow: {
      invalidatesTags: [TAG_WORKFLOWS_BY_LIST],
    },
  },
});
