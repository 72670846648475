import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { Button } from '~/eds';

import { MODAL_CLIENT_PARTY, MODAL_UPLOAD } from '../../../types/modal.types';
import EcPartyItem from '../EcPartyItem';
import styles from './EcPartyTable.styles';

class EcPartyTable extends Component {
  renderPartyListItems() {
    const { handleShowModal, listItems, setInternal } = this.props;
    return listItems.map((item, index) => {
      return (
        <EcPartyItem
          item={item}
          key={`item-${index}`}
          handleShowModal={handleShowModal}
          setInternal={setInternal}
        />
      );
    });
  }

  renderHeaderActionButtons() {
    const {
      classes,
      fileUploadButtonId,
      handleShowModal,
      setInternal,
    } = this.props;
    const showUploadModal = () => {
      setInternal();
      handleShowModal(MODAL_UPLOAD);
    };
    const showClientPartyModal = () => {
      setInternal();
      handleShowModal(MODAL_CLIENT_PARTY);
    };

    return (
      <div className={classes.headerOptionsWrapper}>
        <button className={classes.addNewParty} onClick={showClientPartyModal}>
          <span>Add New Party</span>
        </button>
        <Button
          id={fileUploadButtonId}
          text="Upload"
          onClick={showUploadModal}
          variant="primary"
        />
      </div>
    );
  }

  render() {
    const { classes, listItems, title = 'Parties' } = this.props;

    return (
      <table className={classes.partyTable}>
        <thead>
          <tr>
            <th className={classes.headerColumn}>{title}</th>
            <th className={classes.headerExtraOptionsColumn}>
              {this.renderHeaderActionButtons()}
            </th>
          </tr>
          <tr>
            {listItems.length ? (
              <th className={classes.headerColumn}>Name</th>
            ) : (
              <th>No parties</th>
            )}
            <th className={classes.headerExtraOptionsColumn} />
          </tr>
        </thead>
        <tbody>{this.renderPartyListItems()}</tbody>
      </table>
    );
  }
}

EcPartyTable.propTypes = {
  classes: PropTypes.object.isRequired,
  fileUploadButtonId: PropTypes.string.isRequired,
  listItems: PropTypes.array.isRequired,
  handleShowModal: PropTypes.func,
  setInternal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default injectSheet(styles)(EcPartyTable);
