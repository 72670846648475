import { noop } from 'lodash';
import React from 'react';

import { SearchFilters } from '~/features/filters';

import { FieldModel } from '../types';

// TODO: deprecate for ModelScope
export const ModelPreviewSearchFilters = ({ model }: { model: FieldModel }) => (
  <SearchFilters
    readOnly
    placeholder="–"
    filters={model.config.filters}
    onFilterChange={noop}
  />
);
