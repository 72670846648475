import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SliceType } from '~/enums';

import { WebhooksState as State } from './types';

export const initialState: State = {
  activeEndpoint: null,
  activeEndpointType: null,
  activeAttempt: null,
  activeAttemptType: null,
  selectedEvent: null,
  selectedTab: 'endpoints',
  configuration: {
    url: '',
    description: '',
    events: {},
  },
};

const setConfigurationReducer = (
  state: State,
  action: PayloadAction<State['configuration']>,
) => {
  state.configuration = action.payload;
};

export default createSlice({
  name: SliceType.Webhooks,
  initialState,
  selectors: {
    selectActiveEndpoint: ({ activeEndpoint, activeEndpointType }: State) => ({
      activeEndpoint,
      activeEndpointType,
    }),
    selectSelectedEvent: (state: State) => state.selectedEvent,
    selectConfiguration: (state: State) => state.configuration,
    selectEndpointConfiguration: ({
      activeEndpoint,
      activeEndpointType,
      selectedEvent,
      selectedTab,
      configuration,
    }: State) => {
      return {
        activeEndpoint,
        activeEndpointType,
        selectedEvent,
        selectedTab,
        configuration,
      };
    },
    selectTab: (state: State) => state.selectedTab,
    selectActiveAttempt: ({ activeAttempt, activeAttemptType }: State) => ({
      activeAttempt,
      activeAttemptType,
    }),
  },
  reducers: {
    resetEndpointConfiguration: (state: State) => {
      state.activeEndpoint = null;
      state.activeEndpointType = null;
      state.configuration = initialState.configuration;
      state.selectedEvent = null;
    },
    resetAttemptConfiguration: (state: State) => {
      state.activeAttempt = null;
      state.activeAttemptType = null;
    },
    setActiveEndpoint: (
      state: State,
      action: PayloadAction<State['activeEndpoint']>,
    ) => {
      state.activeEndpoint = action.payload;
    },

    setActiveAttempt: (
      state: State,
      action: PayloadAction<State['activeAttempt']>,
    ) => {
      state.activeAttempt = action.payload;
    },
    setActiveEndpointType: (
      state: State,
      action: PayloadAction<State['activeEndpointType']>,
    ) => {
      state.activeEndpointType = action.payload;
    },

    setActiveAttemptType: (
      state: State,
      action: PayloadAction<State['activeAttemptType']>,
    ) => {
      state.activeAttemptType = action.payload;
    },

    setTab: (state: State, action: PayloadAction<State['selectedTab']>) => {
      state.selectedTab = action.payload;
    },

    setSelectedEvent: (
      state: State,
      action: PayloadAction<State['selectedEvent']>,
    ) => {
      state.selectedEvent = action.payload;
    },

    setUrl: (
      state: State,
      action: PayloadAction<State['configuration']['url']>,
    ) => {
      state.configuration.url = action.payload;
    },
    setDescription: (
      state: State,
      action: PayloadAction<State['configuration']['description']>,
    ) => {
      state.configuration.description = action.payload;
    },
    setEvents: (
      state: State,
      action: PayloadAction<State['configuration']['events']>,
    ) => {
      state.configuration.events = action.payload;
    },
    setConfiguration: setConfigurationReducer,
  },
});
