import { Button, Layout, TruncateText } from '~/eds';

import { Citation as CitationType } from '../../../types';

interface Props {
  citation: CitationType;
  text: string;
  onClick: (citation: CitationType) => void;
}

export const Citation = ({ citation, text, onClick }: Props) => {
  const handleClick = () => {
    onClick(citation);
  };

  return (
    <Layout w="100%" spacing={2} align="center">
      <Button text={text} onClick={handleClick} size="xs" />
      <Layout w="100%" direction="column">
        {citation.entityName && (
          <TruncateText w="90%" variant="tiny">
            {citation.entityName}
          </TruncateText>
        )}
      </Layout>
    </Layout>
  );
};
