import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PageLayout } from '~/eds';
import {
  ModelModal,
  ModelResults,
  ModelVersionInfo,
  usePollModelProgress,
  useResolveTargetEntityDetails,
} from '~/features/x-ray';
import {
  CreditBalance,
  useCreditStatusMessage,
} from '~/features/x-ray/billing';
import { useCreateAction } from '~/features/x-ray/hooks';
import { FlagType, useFlag } from '~/flags';
import { slices } from '~/redux';
import { RoutePathType, useRouting } from '~/routing';

import DEPRECATED_Page from './DEPRECATED_index';

const Page = () => {
  const enableDocumentXRayDevListPageRefactor = useFlag(
    FlagType.DocumentXRayDevListPageRefactor,
  );
  const dispatch = useDispatch();
  const { location } = useRouting();

  const activeModelResultInfo = useSelector(
    slices.xRay.selectors.selectActiveModelResultInfo,
  );

  const [targetEntityDetails] = useResolveTargetEntityDetails(
    activeModelResultInfo?.targetEntity ?? null,
  );

  const activeTargetEntityDetails =
    activeModelResultInfo?.targetEntityDetails ?? targetEntityDetails;

  const creditStatusMessage = useCreditStatusMessage();

  const { progressChip } = usePollModelProgress({
    modelId: activeModelResultInfo?.id,
  });

  const { entityType, title } = resolvePageProps(location.pathname);

  const createAction = useCreateAction(entityType);

  if (!enableDocumentXRayDevListPageRefactor) {
    // eslint-disable-next-line react/jsx-pascal-case
    return <DEPRECATED_Page />;
  }

  const panel = activeModelResultInfo
    ? {
        children: (
          <ModelVersionInfo
            enableTitle
            modelId={activeModelResultInfo.id}
            version={activeModelResultInfo.latestVersion}
          />
        ),
        chips: [progressChip],
        hidden: {
          isHidden: false,
          onHide: () =>
            dispatch(slices.xRay.actions.setActiveModelResultInfo(null)),
        },
        title: activeTargetEntityDetails.label,
        width: 'm' as const,
      }
    : undefined;

  return (
    <PageLayout
      actions={[createAction]}
      headerCalloutContent={<CreditBalance />}
      panel={panel}
      statusMessage={creditStatusMessage}
      title={title}
    >
      <ModelResults
        entityType={entityType}
        onShowResult={(modelResult) =>
          dispatch(
            slices.xRay.actions.setActiveModelResultInfo({
              id: modelResult.id,
              latestVersion: modelResult.latestVersion,
              targetEntity: modelResult.targetEntity,
            }),
          )
        }
      />
      <ModelModal />
    </PageLayout>
  );
};

export default Page;

const resolvePageProps = (
  pathname: string,
): {
  entityType: 'field';
  title: string;
} => {
  switch (pathname) {
    case RoutePathType.AutomationHubFields:
    default:
      return {
        entityType: 'field',
        title: 'Automation Hub',
      };
  }
};
