import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { MODAL_CLIENT_PARTY, MODAL_DELETE } from '../../../types/modal.types';
import ActionsMenu from '../../Shared/ActionsMenu';
import styles from './EcPartyItem.styles';

export const generateId = ({ id, name }) => `party-item%id=${id}&name=${name}`;

class EcPartyItem extends Component {
  renderExtraOptions(parentTestId) {
    const { classes, handleShowModal, item, setInternal } = this.props;
    const showClientPartyModal = () => {
      setInternal();
      handleShowModal(MODAL_CLIENT_PARTY, item);
    };
    const showDeleteModal = () => {
      setInternal();
      handleShowModal(MODAL_DELETE, item);
    };

    const getMenuItems = () => {
      const items = [];

      items.push({
        content: 'Edit Party',
        onClick: () => showClientPartyModal(),
      });

      items.push({
        content: 'Delete Party',
        onClick: () => showDeleteModal(),
      });

      return items;
    };

    return (
      <div
        className={classes.actionsWrapper}
        data-testid={`${parentTestId}-actions-container`}
      >
        <ActionsMenu
          id={`party_item_actions_menu?id=${item.name}`}
          items={getMenuItems()}
          align="end"
          smallIcon
          title="party item actions menu"
        />
      </div>
    );
  }

  render() {
    const { item } = this.props;

    const testId = generateId(item);
    return (
      <tr data-testid={testId}>
        <td data-testid="party-item-name">{item.name}</td>
        <td data-testid="party-item-extra-options">
          {this.renderExtraOptions(testId)}
        </td>
      </tr>
    );
  }
}

EcPartyItem.propTypes = {
  classes: PropTypes.object.isRequired,
  handleShowModal: PropTypes.func,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  setInternal: PropTypes.func.isRequired,
};

export default injectSheet(styles)(EcPartyItem);
