import React from 'react';

import { getFilterValuesLength } from '../../../../evifields';
import { TextContainer } from '../TextContainer';
import { EnumValue, Field, Filter } from '../types';
import { EnumValuesPreview } from './EnumValuesPreview';

interface Props {
  field: Field<'enum_set'>;
  filter: Filter<EnumValue>;
}

export const EnumSetValuesPreview = ({ field, filter }: Props) => {
  const filterValuesLength = getFilterValuesLength(filter);

  return filterValuesLength > 1 ? (
    <TextContainer text={`${filterValuesLength} values`} />
  ) : (
    <EnumValuesPreview field={field} filter={filter} />
  );
};
