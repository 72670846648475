import { capitalize } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  builderReset,
  builderSetShouldEditSetup,
  workflowReset,
} from '~/actions';
import { User } from '~/components/Shared/User';
import { PAGE_START, UI_TABLE_PAGE_SIZE } from '~/constants/page';
import {
  PageLayout,
  parseDate,
  Table,
  types,
  useDebouncedState,
  useToast,
} from '~/eds';
import { QueryParamType, WorkflowPublishActionType } from '~/enums';
import { useCurrentUser, useTrackSegment } from '~/hooks';
import { api, selectors } from '~/redux';
import { useRouting } from '~/routing';
import { ColumnSortOrder, Nullable, Option, Workflow } from '~/types';
import { sortByDateValue } from '~/utils/array';
import {
  getPageSearchQueryByKey,
  updatePageSearchQuery,
} from '~/utils/searchQuery';
import { getClientInfo, getUserClientInfo } from '~/utils/user';

import { Setup } from '../WorkflowBuilderPage';
import { useShareWorkflowModal } from './useShareWorkflowModal';
import { useWorkflowActionModal } from './useWorkflowActionModal';
import { mapVersionCell } from './utils';

export const WorkflowList = () => {
  const [debouncedSearch, searchText, setSearchText] = useDebouncedState('');
  const [selectedWorkflow, setSelectedWorkflow] = useState<Workflow | null>(
    null,
  );
  const [workflowAction, setWorkflowAction] = useState<
    Nullable<WorkflowPublishActionType | 'DELETE'>
  >(null);
  const { navigate } = useRouting();
  const { toast } = useToast();
  const currentUser = useCurrentUser();
  const trackSegment = useTrackSegment();
  const dispatch = useDispatch();
  const shouldEditSetup = useSelector(selectors.selectShouldEditSetup);

  const urlParams = {
    page: getPageSearchQueryByKey([QueryParamType.Page], PAGE_START),
    pageSize: getPageSearchQueryByKey(
      [QueryParamType.PageSize],
      UI_TABLE_PAGE_SIZE,
    ),
    sortedColumn: getPageSearchQueryByKey(
      [QueryParamType.SortedColumn],
      'name',
    ),
    sortedOrder: getPageSearchQueryByKey([QueryParamType.SortedOrder], 'asc'),
  };

  const [sortBy, setSortBy] = useState<ColumnSortOrder>({
    id: urlParams.sortedColumn,
    desc: urlParams.sortedOrder === 'desc',
  });

  const [pageSize, setPageSize] = useState(urlParams.pageSize);

  const [pageIndex, setPageIndex] = useState(urlParams.page);

  const handlePagination = ({ pageIndex }: any) => {
    setPageIndex(pageIndex);
    updatePageSearchQuery({ page: pageIndex }, true);
  };

  const handleSearch = (searchText: string) => {
    setSearchText(searchText);
    setPageIndex(PAGE_START);
  };

  const handlePageSize = (value: number) => {
    updatePageSearchQuery({ pageSize: value }, true);
    setPageSize(value);
  };

  const handleUpdate = (state: any, action: any) => {
    switch (action.type) {
      case 'toggleSortBy':
        if (state.sortBy.length) {
          const { id, desc } = state.sortBy[0];

          if (desc) {
            updatePageSearchQuery(
              { sortedOrder: 'desc', sortedColumn: id },
              true,
            );
          } else {
            updatePageSearchQuery(
              { sortedOrder: 'asc', sortedColumn: id },
              true,
            );
          }

          setSortBy(state.sortBy[0]);
        }
        break;
      default:
        return;
    }
  };

  const {
    data: workflows = [],
    isFetching,
  } = api.endpoints.getWorkflows.useQuery({});

  const [
    publishWorkflow,
    { isLoading: isPublishingWorkflow },
  ] = api.endpoints.publishWorkflow.useMutation();
  const [
    unpublishWorkflow,
    { isLoading: isUnpublishingWorkflow },
  ] = api.endpoints.unpublishWorkflow.useMutation();

  const [
    deleteWorkflow,
    { isLoading: isDeletingWorkflow },
  ] = api.endpoints.deleteWorkflow.useMutation();

  const [totalCount, setTotalCount] = useState(workflows.length);

  const columns = [
    {
      key: 'name',
      cellType: 'link',
      title: 'Name',
      mapCellProps: (workflow: Workflow) => ({
        text: workflow.name,
        pathname: `/workflow/builder/${workflow.id}`,
      }),
    },
    {
      key: 'versions',
      cellType: 'menu',
      title: 'Versions',
      disableSortBy: true,
      disableResizing: true,
      width: 'm',
      mapCellProps: (workflow: Workflow) =>
        mapVersionCell(workflow, (option: Option<string>) =>
          navigate(`/workflow/builder/${option.value}`),
        ),
    },
    {
      key: 'createdBy',
      cellType: 'user',
      title: 'Created By',
      minWidth: 'm',
      disableResizing: true,
      disableSortBy: true,
      mapCellProps: (workflow: Workflow) => ({
        asyncUser: {
          id: workflow.createdBy,
          render: User,
        },
        mode: 'avatar-name',
      }),
    },
    {
      key: 'lastUpdated',
      cellType: 'userDatetime',
      title: 'Last Updated',
      width: 'm',
      disableResizing: true,
      mapCellProps: ({ modifiedDate, lastModifiedBy }: Workflow) => ({
        asyncUser: {
          id: lastModifiedBy,
          render: User,
        },
        mode: 'name',
        datetime: parseDate(modifiedDate),
        format: 'relative',
      }),
    },
    {
      key: 'status',
      cellType: 'chips',
      title: 'Status',
      width: 'm',
      disableResizing: true,
      mapCellProps: (workflow: Workflow) => {
        return {
          chips: [
            {
              text: capitalize(workflow.status),
              status:
                workflow.status === 'published'
                  ? 'success'
                  : workflow.status === 'unpublished'
                  ? 'warning'
                  : 'info',
            },
          ],
        };
      },
    },
  ];

  const filteredWorkflows = useMemo(() => {
    const pWorkflows = [...workflows];
    switch (sortBy.id) {
      case 'name':
        pWorkflows.sort((a, b) =>
          sortBy.desc
            ? b.name.localeCompare(a.name, undefined, { sensitivity: 'base' })
            : a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }),
        );
        break;
      case 'lastUpdated':
        pWorkflows.sort(
          sortByDateValue('modifiedDate', sortBy.desc ? 'desc' : 'asc'),
        );
        break;
      case 'status':
        pWorkflows.sort((a, b) =>
          sortBy.desc
            ? b.status.localeCompare(a.status)
            : a.status.localeCompare(b.status),
        );
        break;
    }

    return pWorkflows.filter((workflow) =>
      workflow.name.toLowerCase().includes(debouncedSearch.toLowerCase()),
    );
  }, [workflows, debouncedSearch, sortBy, pageIndex, pageSize]);

  const paginatedWorkflows = useMemo(
    () =>
      filteredWorkflows.slice((pageIndex - 1) * pageSize, pageIndex * pageSize),
    [filteredWorkflows, pageIndex, pageSize],
  );

  useEffect(() => {
    setTotalCount(filteredWorkflows.length);
  }, [filteredWorkflows.length]);

  useEffect(() => {
    updatePageSearchQuery(urlParams);
  }, []);

  const publishUnplishAction = async () => {
    const request =
      workflowAction === WorkflowPublishActionType.Publish
        ? publishWorkflow
        : workflowAction === 'DELETE'
        ? deleteWorkflow
        : unpublishWorkflow;
    try {
      await request({
        id: selectedWorkflow!.id,
      }).unwrap();
      toast({
        message: `Workflow ${
          workflowAction === 'DELETE'
            ? 'deleted'
            : selectedWorkflow!.status === 'published'
            ? 'unpublished'
            : 'published'
        }.`,
        status: 'success',
      });
    } catch (error) {
      toast({
        message: `Failed to ${
          workflowAction === 'DELETE' ? 'delete' : 'update'
        } the workflow`,
        status: 'danger',
      });
    } finally {
      setSelectedWorkflow(null);
      setWorkflowAction(null);
      hide();
    }
  };

  const [updateWorkflowModal, show, hide] = useWorkflowActionModal({
    isLoading:
      isPublishingWorkflow || isUnpublishingWorkflow || isDeletingWorkflow,
    workflow: selectedWorkflow,
    action: workflowAction,
    onHide: () => {
      setSelectedWorkflow(null);
      setWorkflowAction(null);
    },
    onConfirm: publishUnplishAction,
  });

  const [shareSettingsModal, showShareSettingsModal] = useShareWorkflowModal({
    workflow: selectedWorkflow,
  });

  const getRowActions = (workflow: Workflow) => {
    const actions: types.Action<string>[] = [
      {
        label: 'Edit',
        value: 'edit',
        onClick: () => {
          navigate(`/workflow/builder/${workflow.id}`);
        },
      },
    ];

    actions.push({
      label: workflow.status === 'published' ? 'Unpublish' : 'Publish',
      value: workflow.status === 'published' ? 'unpublish' : 'publish',
      tooltip: workflow.canPublish
        ? ''
        : 'Your workflow draft requires fixes before it can be published',
      disabled: workflow.canPublish ? false : true,
      onClick: () => {
        setSelectedWorkflow(workflow);
        setWorkflowAction(
          workflow.status === 'published'
            ? WorkflowPublishActionType.Unpublish
            : WorkflowPublishActionType.Publish,
        );
        show();
      },
    });

    actions.push({
      label: 'Delete',
      value: 'delete',
      onClick: () => {
        setSelectedWorkflow(workflow);
        setWorkflowAction('DELETE');
        show();
      },
    });

    actions.push({
      label: 'Share',
      value: 'share',
      onClick: () => {
        setSelectedWorkflow(workflow);
        showShareSettingsModal();
      },
    });

    return actions;
  };

  function handleCreateWorkflow() {
    dispatch(builderReset());
    dispatch(builderSetShouldEditSetup(true));
    dispatch(workflowReset());
    trackSegment('User Begins New Workflow', {
      groupId: getClientInfo(currentUser),
      userId: getUserClientInfo(currentUser),
    });
  }

  if (shouldEditSetup) {
    return <Setup />;
  }

  return (
    <>
      <PageLayout
        loadingContent={{
          isLoading: isFetching,
          message: 'Loading workflows...',
        }}
        title="Workflow Builder"
        actions={
          currentUser?.['is_workflow_admin']
            ? [
                {
                  level: 'primary',
                  text: 'New Workflow',
                  onClick: handleCreateWorkflow,
                },
              ]
            : []
        }
      >
        <Table
          name="workflows"
          columns={columns}
          data={paginatedWorkflows}
          state={{
            pageIndex,
            sortBy: [sortBy],
          }}
          options={{
            enableSearch: true,
            enableExportXlsx: false,
            enableManageColumns: false,
            enableSelectRows: false,
            enablePagination: true,
            enablePageSizeSelect: true,
            enableEmptyState: true,
          }}
          onPaginate={handlePagination}
          onPageSizeChange={handlePageSize}
          onUpdate={handleUpdate}
          isLoading={isFetching}
          onSearch={handleSearch}
          searchText={searchText}
          totalCount={totalCount}
          reactTableOptions={{
            autoResetSortBy: false,
            disableSortRemove: true,
            manualSortBy: true,
          }}
          getRowActions={getRowActions}
        />
        {updateWorkflowModal}
        {shareSettingsModal}
      </PageLayout>
    </>
  );
};
