import React from 'react';
import { connect } from 'react-redux';

import {
  workflowSetArchiveLocation,
  workflowSetArchiveLocationFilePath,
} from '~/actions/workflow';
import { Icon } from '~/eds';
import { FlexLayout, Text, withInputError } from '~/ui';
import { getArchiveLocationPath, validateFolderPath } from '~/utils/folders';
import { pathObjectToString } from '~/utils/workflow';

import Section from '../Section';
import ArchiveLocationInput from './ArchiveLocationInput';
import ArchiveLocationPath from './ArchiveLocationPath';

function LocationPreview({
  archiveLocation,
  error,
  fields,
  filePathPreview,
  id,
}) {
  const filePathString = pathObjectToString(archiveLocation.filePath, fields);
  const fileNameString = pathObjectToString(archiveLocation.fileName, fields);

  const finalOutput = filePathString
    ? `${filePathPreview}/${filePathString}/${fileNameString}`
    : `${filePathPreview}/${fileNameString}`;

  return (
    <FlexLayout flexDirection="column" space={2}>
      <FlexLayout
        id={id}
        alignItems="center"
        px={4}
        py={2}
        space={2}
        sx={{
          bg: 'black-alpha-04',
          border: error ? 'border' : 'none',
          borderColor: error ? 'red-400' : 'none',
          borderRadius: 'm',
        }}
      >
        <Icon icon="folder" />
        <Text color="gray-600" variant="xs-dense-medium">
          {finalOutput}
        </Text>
      </FlexLayout>
    </FlexLayout>
  );
}

function validateArchiveLocationPreview({
  filePathPreview,
  fields,
  archiveLocation,
}) {
  const filePathString = pathObjectToString(archiveLocation.filePath, fields);
  const fileNameString = pathObjectToString(archiveLocation.fileName, fields);

  const finalOutput = filePathString
    ? `${filePathPreview}/${filePathString}/${fileNameString}`
    : `${filePathPreview}/${fileNameString}`;

  return validateFolderPath(finalOutput) ? null : 'Invalid folder path.';
}

function formatNamingOutput(string) {
  return string.replace(/\//g, '');
}

function ArchiveLocation({
  //connected
  archiveLocation,
  fields,
  workflowSetArchiveLocation,
  workflowSetArchiveLocationFilePath,
  folderPath,
}) {
  const LocationPreviewWithError = withInputError(
    LocationPreview,
    validateArchiveLocationPreview,
  );

  const { pilotFolderId, filePath, fileName } = archiveLocation;

  const placeholder = (
    <Text color="gray-600" sx={{ fontStyle: 'italic' }}>
      [Folder placeholder]
    </Text>
  );

  const filePathPreview =
    (folderPath && folderPath[pilotFolderId]) || placeholder;

  return (
    <Section
      description={
        <Text color="gray-600" variant="xs-dense-medium">
          Specify the destination and filename. Paths and filenames can be
          dynamic, generated by fields. <br />
          <Text color="black" variant="xs-dense-medium">
            Use # to access dynamic tags
          </Text>{' '}
          and separate sub folders by{' '}
          <Text color="black" variant="xs-dense-medium">
            using "/" between tags
          </Text>
          .
        </Text>
      }
      icon="field-folder"
      title="Archive Location"
    >
      <ArchiveLocationPath
        folderPath={folderPath}
        folderId={pilotFolderId}
        onChange={workflowSetArchiveLocationFilePath}
      />
      <ArchiveLocationInput
        archiveLocation={filePath}
        fields={fields}
        id="workflow--settings-sub-folder-entry"
        label="Sub folder"
        placeholder="Specify sub folder"
        onChange={(filePath) => workflowSetArchiveLocation({ filePath })}
      />
      <ArchiveLocationInput
        archiveLocation={fileName}
        fields={fields}
        id="workflow--settings-naming-format-entry"
        label="Naming format"
        placeholder="Specify file name"
        formatOutput={formatNamingOutput}
        onChange={(fileName) => workflowSetArchiveLocation({ fileName })}
      />
      <LocationPreviewWithError
        archiveLocation={archiveLocation}
        fields={fields}
        filePathPreview={filePathPreview}
        id="workflow--settings-naming-convention-preview"
      />
    </Section>
  );
}

function mapStateToProps({ workflow, client }) {
  // only include fields that are assigned and have valid types defined.
  return {
    archiveLocation: workflow.settings.archiveLocation,
    fields: workflow.fields,
    folderPath: getArchiveLocationPath(
      client.folderTree,
      workflow.settings.archiveLocation.pilotFolderId,
    ),
  };
}

export default connect(mapStateToProps, {
  workflowSetArchiveLocation,
  workflowSetArchiveLocationFilePath,
})(ArchiveLocation);
