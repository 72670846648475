import React, { useCallback, useEffect, useState } from 'react';

import { PersistedTable } from '~/components/Shared/PersistedTable';
import { DEFAULT_ACCEPTED_FILES } from '~/constants/files';
import { ContentContainer, FileInput, Layout, PageLayout } from '~/eds';
import { TableContextType } from '~/enums';
import {
  AlgoStatusPanel,
  formatUploadSize,
  isFileUploading,
  mapUploadStatusChipProps, //UploadDocumentsModal,
  UploadDocumentsPanel,
  UploadFile,
} from '~/features/upload';
import { FlagType, useFlag } from '~/flags';
import { useCurrentUser, useTableSettings } from '~/hooks';
import { api } from '~/redux';
import { Nullable } from '~/types';
import { testIsSuperAdmin } from '~/utils/user';

import DEPRECATED_UploadPage from './DEPRECATED_index';

const Page = () => {
  const enableNewUploadPage = useFlag(FlagType.UploadPageNew);
  const user = useCurrentUser();
  const isSuperAdmin = testIsSuperAdmin(user);

  const {
    tableSettings = {
      pageSize: 10,
    },
  } = useTableSettings(TableContextType.UploadFiles);

  const [activeFile, setActiveFile] = useState<Nullable<UploadFile>>(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(tableSettings.pageSize);
  const [files, setFiles] = useState<File[]>([]);

  const {
    data,
    error,
    isFetching: isLoading,
  } = api.endpoints.getUploadFiles.useQuery({ page, pageSize });

  const handleHideModal = () => {
    setFiles([]);
  };

  const handleRemoveFile = (
    removedFile: File,
    removedFileIndex: number | undefined,
  ) => {
    if (removedFileIndex !== undefined) {
      const updatedFiles = files.filter((_file, i) => removedFileIndex !== i);
      setFiles(updatedFiles);
    }
  };

  const handleFilesChange = (newFiles: File[]) => {
    setFiles([
      ...files,
      ...newFiles.filter((file) => !files.some((f) => f.name === file.name)),
    ]);
  };

  useEffect(() => {
    if (tableSettings) {
      setPageSize(tableSettings.pageSize);
    }
  }, [tableSettings]);

  const onPaginate = useCallback(
    ({ pageIndex }: { pageIndex: number }) => {
      if (pageIndex !== page) {
        setPage(pageIndex);
      }
    },
    [page],
  );

  const onPageSizeChange = useCallback((pageSize: number) => {
    setPage(1);
    setPageSize(pageSize);
  }, []);

  if (!enableNewUploadPage) {
    // eslint-disable-next-line react/jsx-pascal-case -- deprecating
    return <DEPRECATED_UploadPage />;
  }

  const placeholderContent =
    data?.count === 0
      ? {
          message: 'You haven’t uploaded any documents yet…',
        }
      : error
      ? {
          message: 'An error occurred while loading the upload logs…',
        }
      : undefined;

  const enableAlgoStatusColumn = isSuperAdmin
    ? [
        {
          cellType: 'link',
          key: 'algostatus',
          title: 'AlgoStatus',
          mapCellProps: (d: UploadFile) => ({
            text: 'Show Status',
            onClick: () => {
              setActiveFile(d);
            },
          }),
        },
      ]
    : [];

  const columns = [
    {
      key: 'name',
      cellType: 'filesystem',
      title: 'Document Name',
      mapCellProps: (d: UploadFile) => ({
        //a link only when file has finished processing
        file: {
          id: `${d.id}`,
          name: `${d.name}${d.fileType}`,
          link: {
            pathname: `/document/${d.id}`,
            disabled: isFileUploading(d.status),
            tooltip: isFileUploading(d.status)
              ? `Document is not processed: ${d.status}`
              : 'View document in new tab',
          },
        },
      }),
      width: 'auto',
    },
    {
      key: 'size',
      cellType: 'number',
      title: 'Size',
      mapCellProps: (d: UploadFile) => ({
        number: formatUploadSize(d).amount,
        options: {
          unit: formatUploadSize(d).unit,
          enablePluralUnits: false,
        },
      }),
      width: 'm',
    },
    {
      key: 'uploadDate',
      cellType: 'datetime',
      title: 'Upload Date',

      mapCellProps: (d: UploadFile) => ({
        datetime: d.dateAdded ? new Date(d.dateAdded) : undefined,
        format: 'full',
      }),
      width: 'l',
    },

    {
      key: 'status',
      cellType: 'chips',
      title: 'Status',

      mapCellProps: (d: UploadFile) => ({
        chips: [mapUploadStatusChipProps(d)],
      }),
    },
    ...enableAlgoStatusColumn,
  ];

  return (
    <PageLayout
      loadingContent={{ isLoading, message: 'Loading upload files' }}
      title="Upload Documents"
    >
      <Layout preset="sections">
        <FileInput
          accept={DEFAULT_ACCEPTED_FILES}
          isMulti={true}
          name="Upload Files Dropzone"
          mode="card"
          onChange={(uploadedFiles) => {
            setFiles(uploadedFiles ?? []);
          }}
          placeholder="Drag document(s) here to upload them."
          value={null}
        />
        <Layout direction="row" spacing={2}>
          <ContentContainer placeholderContent={placeholderContent}>
            <PersistedTable
              columns={columns}
              context={TableContextType.UploadFiles}
              data={data?.results}
              isLoading={isLoading}
              name="Upload Files"
              options={{
                enableExportXlsx: false,
                enablePageSizeSelect: false,
                enablePagination: true,
                enableManageColumns: false,
                enableSelectRows: false,
              }}
              state={{
                pageIndex: page,
                pageSize,
              }}
              onPageSizeChange={onPageSizeChange}
              onPaginate={onPaginate}
              totalCount={data?.count}
            />
          </ContentContainer>
        </Layout>
        {activeFile && (
          <AlgoStatusPanel
            activeFile={activeFile}
            onHide={() => setActiveFile(null)}
          />
        )}
        {files.length > 0 && (
          <UploadDocumentsPanel
            files={files}
            onHide={handleHideModal}
            onFilesChange={handleFilesChange}
            onRemoveFile={handleRemoveFile}
          />
        )}
      </Layout>
    </PageLayout>
  );
};

export default Page;
