import { Span } from '@opentelemetry/api';
import { CommentElementBox } from '@syncfusion/ej2-react-documenteditor';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';

import { createTraceSpan } from '~/components/App/AppTracingInitializer';
import { CommentDraftWithMentions } from '~/components/Shared/Comments';
import {
  ContextMenuType,
  ContextMenuValues,
} from '~/components/Shared/ContextMenu';
import {
  convertSfdtToFile,
  DOCUMENT_START_POSITIONS,
  DocumentEditor as EJ2DocumentEditor,
  updateRevisions,
  useDocumentEditorContext,
} from '~/components/Shared/DocumentEditor';
import { showToast } from '~/components/Shared/EcToast';
import { TraceAction, TraceName } from '~/constants/trace';
import { useAppConfigContext, usePusherContext } from '~/contexts';
import { Button, ContentContainer, getUserName, types, useModal } from '~/eds';
import { EntityType, PusherEventType } from '~/enums';
import { useTrackDocumentEditors } from '~/features/document';
import { Highlight } from '~/features/document-viewer';
import { DocumentOnlineEditorButton } from '~/features/wopi/DocumentOnlineEditorButton';
import { FlagType, useFlag } from '~/flags';
import { useCurrentUser, useUsers } from '~/hooks';
import { api, coerceRtkqError } from '~/redux';
import { TAGS } from '~/redux/api';
import { useRouting } from '~/routing';
import {
  Comment,
  MentionEntityType,
  Nullable,
  TicketDocumentVersion,
  Uuid,
} from '~/types';
import * as toastTypes from '~/types/toast.types';
import { captureException, serializeContentAstWithEmail } from '~/utils';

import { DOCUMENT_COMMENT_CHIP, SAVE_DOCUMENT_CACHE_KEY } from '../constants';
import { useDocumentCommentImportStatus } from '../useDocumentCommentImportStatus';
import { ContextMenu } from './ContextMenu';
import { ContextMenuOption, LayoutViewType } from './types';
import { useTrackEditHistory } from './useTrackEditHistory';

interface Props {
  /** the current document version */
  currentVersion: TicketDocumentVersion;
  /** the document id */
  documentId: Uuid;
  /** document version in pilot */
  documentVersionId: number;
  /** If the document was generated from a template */
  isGeneratedFromTemplate: boolean;
  /** If the clauses are being processed */
  isProcessingClauses: boolean;
  /** is the latest version */
  isLatestVersion: boolean;
  /** whether the ticket is in edit or review stage */
  isTicketInEditReviewStage: boolean;
  /** the ticket id */
  ticketId: Uuid;
  activeHighlightId: string | number;
  /** version if of the compared document version */
  comparingTargetVersionId?: Uuid;
  /** the context menu options to be rendered when selecting text */
  contextMenuOptions?: ContextMenuType[];
  /** the highlights that will be rendered in the document */
  highlights?: Highlight<{ type: string }>[];
  /** if the Edit button will disabled or not, defaults to false */
  isEditButtonDisabled?: boolean;
  /** if the document is read only */
  isReadOnly?: boolean;
  /** if the file type is a '.wopitest(x)' file */
  isWopitestType?: boolean;
  /** The enabled mode for the editor. Defaults to "edit" */
  layoutView?: LayoutViewType;
  /** Loading message */
  loadingMessage?: string;
  /** Trigger to create the highlights */
  shouldCreateHighlights?: boolean;
  /** function to resolve the document content, must be passed to generate source and clause highlights */
  contentResolver?: () => { words: { word: string; id: Nullable<number> }[] };
  /** callback when the clauses creation in document editor finishes */
  onClausesCreated?: () => void;
  /** callback when comment mark is clicked */
  onCommentClick?: (id: string) => void;
  /** callback when the document content change. */
  onContentChange?: () => void;
  /** callback when context menu is opened. */
  onContextMenuOpen?: () => void;
  /** callback when there is an error creating the comment */
  onCreateCommentError?: () => void;
  /** callback after the comment is created*/
  onCreateComment?: (comment: Comment) => void;
  /** callback after the comment is deleted*/
  onDeleteComment?: (comment: CommentElementBox) => void;
  /** callback after the document is loaded */
  onDocumentLoad?: () => void;
  /** callback when mentioning users in comments */
  onMention?: (mention: types.MentionedEntity<MentionEntityType>) => void;
  /** callback when redlines finish loading */
  onRedlinesLoaded?: () => void;
  /** callback when the text selection finished */
  onSelectionFinish?: () => void;
}

const ONE_MIN_IN_MS = 60 * 1000;
const TEN_MIN_IN_MS = 10 * ONE_MIN_IN_MS;

type ModalConfig = {
  title: string;
  primaryAction: {
    text: string;
  };
  cancelText: string;
  children: string;
};

const ModalConfigs: Record<string, ModalConfig> = {
  bumpTemplate: {
    title: 'Edit Document From Template',
    primaryAction: {
      text: 'Edit',
    },
    cancelText: 'Cancel',
    children:
      "This document was generated from a template, editing it will cause the intake form to no longer generate a document. Proceeding to editing will generate a new version that's editable.",
  },
  bumpCounterparty: {
    title: 'Edit Counterparty Document',
    primaryAction: {
      text: 'Edit',
    },
    cancelText: 'Cancel',
    children:
      "This document is marked as a counterparty document. Proceeding to editing will generate a new version that's editable.",
  },
};

export function TicketReviewerDocumentEditor({
  activeHighlightId,
  comparingTargetVersionId,
  contextMenuOptions: initialContextMenuOptions = [],
  currentVersion,
  documentId,
  highlights,
  isEditButtonDisabled = false,
  isGeneratedFromTemplate,
  isLatestVersion,
  isReadOnly,
  isTicketInEditReviewStage,
  isWopitestType = false,
  layoutView = 'edit',
  loadingMessage,
  ticketId,
  contentResolver,
  onCommentClick,
  onContentChange,
  onContextMenuOpen,
  onCreateComment,
  onCreateCommentError,
  onDeleteComment,
  onDocumentLoad,
  onMention,
  onSelectionFinish,
  onRedlinesLoaded,
}: Props) {
  //state
  const inactivityTimeoutRef = useRef<Nullable<NodeJS.Timeout>>(null);
  const [docEditorUser, setDocEditorUser] = useState<Nullable<types.User>>(
    null,
  );
  const [shouldCloseModal, setShouldCloseModal] = useState(false);
  const originalDocument = useRef('');
  const spanTraceRef = useRef<Span | null>(null);
  const spanFetchDocumentTraceRef = useRef<Span | null>(null);
  const [modalConfig, setModalConfig] = useState<ModalConfig>(
    ModalConfigs.bumpTemplate,
  );
  const [comparingDocument, setComparingDocument] = useState<Nullable<object>>(
    null,
  );

  //derived state
  const versionId = currentVersion.id;
  const ticketEntity = {
    type: EntityType.Ticket as const,
    id: ticketId,
  };

  const versionEntity = {
    type: EntityType.TicketDocumentVersion as const,
    id: versionId,
  };
  const isDocumentCommentImportEnabled = useFlag(
    FlagType.DocumentCommentImport,
  );
  const isWordEditingOnlineEnabled = useFlag(
    FlagType.WordEditingOnlineWorkflow,
  );

  const isEditLayoutView = layoutView === 'edit';

  //hooks
  const currentUser = useCurrentUser();
  const users = useUsers();
  const { navigate } = useRouting();
  const dispatch = useDispatch();
  const { pusher } = usePusherContext();

  useTrackEditHistory({ documentId, versionId });

  const {
    start: startInactivityTimer,
    reset: resetInactivityTimer,
    pause: pauseInactivityTimer,
  } = useIdleTimer({
    timeout: TEN_MIN_IN_MS,
    startOnMount: false,
    startManually: true,
    stopOnIdle: true,
    onIdle: onUserEditingIsIdle,
    eventsThrottle: 500,
  });

  const {
    edit,
    editors,
    stopEdit,
    isReady: isEj2EditorsReady,
  } = useTrackDocumentEditors({
    documentEditor: 'ej2',
    versionId,
  });

  const {
    editors: wopiEditors,
    isReady: isWopiEditorsReady,
  } = useTrackDocumentEditors({
    documentEditor: 'wopi',
    versionId,
  });

  const isWopiBeingUsed = wopiEditors.size > 0;
  const isEj2BeingUsed = editors.size > 0;

  const isCurrentUserEditing = useMemo(
    () => editors.has(currentUser.id.toString()),
    [editors],
  );

  const isAnotherUserEditing = useMemo(
    () => editors.size > 0 && !isCurrentUserEditing,
    [editors, isCurrentUserEditing],
  );

  useEffect(() => {
    isWordEditingOnlineEnabled && subscribeToWopiUpdates();
    return () => {
      pusher.unsubscribe(versionId);
    };
  }, []);

  /**
   * this functions subscribes and listen to Pusher envents
   * when WOPI finishes an editing session
   */
  const subscribeToWopiUpdates = () => {
    const channel = pusher.subscribe(versionId);
    channel.bind(PusherEventType.WopiDocumentSave, () => {
      fetchDocumentSfdt({ documentId, versionId });
    });
  };

  useEffect(() => {
    if (isEj2EditorsReady && isWopiEditorsReady) {
      // we check if there was a valid docEditorUser and if that user wasn't the current user when we update the editors,
      // if true, we refetch the document
      if (
        docEditorUser &&
        docEditorUser.id !== currentUser.id &&
        editors.size === 0
      ) {
        fetchDocumentSfdt({ documentId, versionId });
      }

      // if the current user was the one editing, we need to clear the inactivity timeout
      if (
        docEditorUser &&
        docEditorUser.id === currentUser.id &&
        editors.size === 0
      ) {
        inactivityTimeoutRef.current &&
          clearTimeout(inactivityTimeoutRef.current);
      }
      setDocEditorUser(users[Number(editors.values().next().value)]);
    }
  }, [editors, users, isEj2EditorsReady, isWopiEditorsReady]);

  const {
    isDocumentReady: isSfdtDocumentReady,
    setIsLoading: setIsDocumentEditorLoading,
    comments,
    documentEditor,
    deleteComment,
  } = useDocumentEditorContext();
  const { sidebarWidth } = useAppConfigContext();

  //rtkq
  const [saveDocument] = api.endpoints.saveDocumentSfdt.useMutation({
    fixedCacheKey: SAVE_DOCUMENT_CACHE_KEY,
  });
  const [
    fetchDocumentSfdt,
    {
      isLoading: isLoadingDocumentSfdt,
      isError: isDocumentSfdtError,
      error: documentSfdtError,
      data: documentSfdt,
      isFetching: isFetchingDocumentSfdt,
    },
  ] = api.endpoints.getDocumentSfdt.useLazyQuery();
  const [
    createComment,
    { isLoading: isCreatingComment },
  ] = api.endpoints.createComment.useMutation();

  const {
    data: compareDocumentdocumentSfdt,
    isFetching: isFetchingCompareDocuments,
    isError: isCompareDocumentsError,
  } = api.endpoints.compareDocumentsSfdt.useQuery(
    {
      referenceVersionId: comparingTargetVersionId!,
      targetVersionId: versionId,
      documentId: documentId,
    },
    { skip: !comparingTargetVersionId },
  );

  useEffect(() => {
    compareDocumentdocumentSfdt &&
      setComparingDocument(compareDocumentdocumentSfdt);
  }, [compareDocumentdocumentSfdt]);

  const [
    bumpVersion,
    { isLoading: isBumpingVersion },
  ] = api.endpoints.bumpVersion.useMutation();

  const [isComparing, setIsComparing] = useState(
    Boolean(comparingTargetVersionId),
  );

  const saveAndSetIsComparing = async () => {
    if (
      documentEditor &&
      !documentEditor.isDocumentEmpty &&
      isCurrentUserEditing &&
      !isComparing
    ) {
      const documentBlob = await documentEditor.saveAsBlob('Sfdt');
      const documentFile = new File([documentBlob], 'document.json', {
        type: 'application/json',
      });
      await saveDocument({
        documentId,
        versionId,
        isDoneEditing: false,
        document: documentFile,
      });
    }
    setIsComparing(true);
  };

  useEffect(() => {
    if (Boolean(comparingTargetVersionId)) {
      saveAndSetIsComparing();
    } else {
      setIsComparing(false);
      setComparingDocument(null);
    }
  }, [comparingTargetVersionId]);

  useDocumentCommentImportStatus({
    versionId,
    documentId,
    skip: !documentSfdt || !isDocumentCommentImportEnabled,
  });

  // effects
  useEffect(() => {
    if (versionId && documentId && !isWopitestType) {
      const span = createTraceSpan({
        name: `${TraceName.DocumentEditor}.${TraceAction.FetchAndLoadDocument}`,
        additionalAttributes: {
          versionId,
          documentId,
        },
      });
      spanFetchDocumentTraceRef.current = span;
      spanFetchDocumentTraceRef.current.addEvent('fetching document');
      fetchDocumentSfdt({
        documentId,
        versionId,
      }).then(() => {
        spanFetchDocumentTraceRef.current?.addEvent(
          'finished fetching document',
        );
      });
    }
  }, [versionId, documentId]);

  useEffect(() => {
    if (shouldCloseModal) {
      hideUserInactiveModal();
      setShouldCloseModal(false);
    }
  }, [shouldCloseModal]);

  useEffect(() => {
    setIsDocumentEditorLoading(
      isFetchingDocumentSfdt || isFetchingCompareDocuments,
    );
  }, [isFetchingDocumentSfdt, isFetchingCompareDocuments]);

  useEffect(() => {
    if (isComparing) {
      isCurrentUserEditing && unlockDocEditor();
    }
  }, [isComparing]);

  useEffect(() => {
    const onClick = (comment: CommentElementBox) => () => {
      onCommentClick?.(comment.commentId);
      // we don't know if this click will be before or after the navigation to
      // the comment, so we wait a couple of ms to make sure the clear selection works
      setTimeout(() => {
        if (documentEditor) {
          documentEditor.selection.clear();
          documentEditor.resize();
        }
      }, 100);
    };
    if (
      documentEditor?.documentHelper &&
      isSfdtDocumentReady &&
      onCommentClick
    ) {
      comments.forEach((comment) => {
        const commentButton =
          comment?.commentEnd?.commentMark ??
          comment?.commentStart?.commentMark;
        if (commentButton) {
          commentButton.addEventListener('click', onClick(comment));
        }
      });
    }
    return () => {
      documentEditor?.documentHelper?.comments?.forEach((comment) => {
        const commentButton =
          comment?.commentEnd?.commentMark ??
          comment?.commentStart?.commentMark;
        if (commentButton) {
          commentButton.removeEventListener('click', onClick(comment));
        }
      });
    };
  }, [isSfdtDocumentReady, onCommentClick]);

  /** this is basically a "hack" to be able to watch for when a comment is deleted without the api e.g with a basckpace */
  function _deleteCommentWidget(comment: CommentElementBox) {
    onDeleteComment?.(comment);
    // override this function
    (documentEditor?.editor as any)._deleteCommentWidget(comment);
  }

  useEffect(() => {
    if (documentEditor && documentEditor.editor && onDeleteComment) {
      if (!(documentEditor.editor as any)._deleteCommentWidget) {
        (documentEditor.editor as any)._deleteCommentWidget =
          documentEditor.editor.deleteCommentWidget;
      }
      documentEditor.editor.deleteCommentWidget = _deleteCommentWidget.bind(
        documentEditor.editor,
      );
    }
  }, [isSfdtDocumentReady, onDeleteComment]);

  const [
    userInactiveModal,
    showUserInactiveModal,
    hideUserInactiveModal,
  ] = useModal({
    title: 'Automatic lose access to edit for inactivity',
    primaryAction: {
      text: 'Done editing',
      onClick: () => {
        saveDocumentAndReleaseEditor();
        hideUserInactiveModal();
      },
    },
    cancelText: 'Continue editing',
    onHide: () => {
      inactivityTimeoutRef.current &&
        clearTimeout(inactivityTimeoutRef.current);
    },
    onCancel: () => {
      resetInactivityTimer();
      startInactivityTimer();
    },
    children:
      'You will lose access to the document editor in 1 minute for inactivity. ',
  });

  const toggleViewMode = () => {
    if (
      isSfdtDocumentReady &&
      documentEditor?.documentHelper &&
      documentEditor.isDocumentLoaded
    ) {
      const docJson = documentEditor.serialize();
      if (['final', 'original'].includes(layoutView)) {
        // backup the current document to be able to restore it later if we don't have one already
        originalDocument.current = originalDocument.current || docJson;
        // loads document if we have one stored or use current document
        documentEditor.open(originalDocument.current);
        updateRevisions(documentEditor);
        // we need to enable editing to accept/reject revisions
        try {
          documentEditor.isReadOnly = false;
          // accept/reject only necessary revisions
          documentEditor?.revisions?.handleRevisionCollection(
            layoutView === 'final',
            (documentEditor?.trackChangesPane as any)?.sortedRevisions || [],
          );
          setIsDocumentEditorLoading(false);
        } catch (e) {
          captureException(
            'An error occurred when trying to accept/reject revisions.',
            e,
            {
              section: 'ej2 document editor',
            },
          );

          showToast(
            toastTypes.ERROR,
            `An error occurred when trying to show ${layoutView} document. Please try again or contact support if the issue persists.`,
          );
          setTimeout(() => {
            documentEditor.open(originalDocument.current);
            setIsDocumentEditorLoading(false);
          }, 200);
        } finally {
          documentEditor.isReadOnly = true;
        }

        // set to the beggining of the document
        documentEditor.selection.select(
          DOCUMENT_START_POSITIONS,
          DOCUMENT_START_POSITIONS,
        );
        // if we have a backup document we load it in "edit" layout and clear the backup
      } else if (originalDocument && isEditLayoutView) {
        documentEditor.open(originalDocument.current);
        originalDocument.current = '';
        setIsDocumentEditorLoading(false);
      }
    }
  };

  useEffect(() => {
    if (
      isSfdtDocumentReady &&
      documentEditor?.documentHelper &&
      documentEditor.isDocumentLoaded
    ) {
      setIsDocumentEditorLoading(true);
      setTimeout(() => {
        toggleViewMode();
      }, 100);
    }
  }, [layoutView]);

  function onUserEditingIsIdle() {
    showUserInactiveModal();
    inactivityTimeoutRef.current = setTimeout(() => {
      saveDocumentAndReleaseEditor();
      setShouldCloseModal(true);
    }, ONE_MIN_IN_MS);
  }

  const onDocumentLoadHandler = () => {
    if (spanFetchDocumentTraceRef.current) {
      spanFetchDocumentTraceRef.current.addEvent('finished loading document');
      spanFetchDocumentTraceRef.current.end();
      spanFetchDocumentTraceRef.current = null;
    }
    spanTraceRef.current?.end();
    spanTraceRef.current = null;

    onDocumentLoad?.();

    if (isComparing && compareDocumentdocumentSfdt) {
      onRedlinesLoaded?.();
    }
  };

  const onBeforeDocumentLoad = () => {
    spanFetchDocumentTraceRef.current?.addEvent('loading document');
    const span = createTraceSpan({
      name: `${TraceName.DocumentEditor}.${TraceAction.LoadDocument}`,
      additionalAttributes: {
        versionId,
        documentId,
      },
    });
    spanTraceRef.current = span;
  };

  const onDocumentSave = async (file: File, isDoneEditing: boolean) => {
    isDoneEditing && setIsDocumentEditorLoading(true);
    return saveDocument({
      documentId,
      versionId,
      isDoneEditing,
      document: file,
    })
      .then(() => {
        dispatch(api.util.invalidateTags([TAGS.TAG_BY_ACTIVITY_LOG]));
      })
      .finally(() => {
        isDoneEditing && setIsDocumentEditorLoading(false);
      });
  };

  const saveDocumentAndReleaseEditor = async () => {
    if (documentEditor) {
      const documentBlob = await documentEditor.saveAsBlob('Sfdt');
      const documentFile = new File([documentBlob], 'document.json', {
        type: 'application/json',
      });
      saveDocument({
        documentId,
        versionId,
        isDoneEditing: true,
        document: documentFile,
      }).finally(() => {
        dispatch(api.util.invalidateTags([TAGS.TAG_BY_ACTIVITY_LOG]));
        unlockDocEditor();
      });
    }
  };

  async function bumpDocumentVersionForEditing() {
    try {
      const newVersion = await bumpVersion({ documentId, versionId }).unwrap();
      showToast(toastTypes.SUCCESS, 'A new version was created for editing.');
      dispatch(
        api.util.invalidateTags([
          TAGS.TAG_CURRENT_VERSION,
          TAGS.TAG_BY_ACTIVITY_LOG,
        ]),
      );
      navigate(
        `/workflow/tickets/${ticketId}/reviewer/${newVersion?.versionNumber}`,
      );
    } catch {
      showToast(
        toastTypes.ERROR,
        'An error occurred when trying to generate a new version for editing.',
      );
    }
  }

  function unlockDocEditor() {
    stopEdit();
    pauseInactivityTimer();
  }

  const [bumpVersionModal, showBumpVersionModal] = useModal({
    title: modalConfig.title,
    primaryAction: {
      ...modalConfig.primaryAction,
      onClick: bumpDocumentVersionForEditing,
      isLoading: isBumpingVersion,
    },
    cancelText: modalConfig.cancelText,
    children: modalConfig.children,
  });

  function onDocEditorEditButtonClick() {
    if (isGeneratedFromTemplate || currentVersion.isCounterparty) {
      setModalConfig(
        ModalConfigs[
          isGeneratedFromTemplate ? 'bumpTemplate' : 'bumpCounterparty'
        ],
      );
      showBumpVersionModal();
    } else {
      if (!isCurrentUserEditing) {
        if (isAnotherUserEditing) {
          showToast(toastTypes.ERROR, 'Editor is being used.');
        } else {
          edit();
          startInactivityTimer();
        }
      } else {
        unlockDocEditor();
      }
    }
  }

  const contextMenuOptions: ContextMenuOption[] = [
    ...initialContextMenuOptions,
    (_show, hide) => ({
      icon: 'message',
      tooltip: 'Add Comment',
      value: ContextMenuValues.Comments,
      hidden: !isCurrentUserEditing,
      component: (
        <CommentDraftWithMentions
          entity={ticketEntity}
          isSubmitting={isCreatingComment}
          isFocused
          chips={[DOCUMENT_COMMENT_CHIP]}
          onMention={onMention}
          onSubmit={(comment) => {
            if (documentEditor) {
              const commentText = serializeContentAstWithEmail(
                comment.content,
                users,
              );
              documentEditor.editor.insertComment(commentText);
              const newEditorComment =
                documentEditor.documentHelper.currentSelectedComment;
              const commentWithContext = {
                ...comment,
                context: [
                  versionEntity,
                  {
                    type: EntityType.EditorHighlight as const,
                    id: newEditorComment.commentId,
                  },
                ],
                threadId: null,
              };
              createComment(commentWithContext)
                .unwrap()
                .then((createdComment) => {
                  createdComment && onCreateComment?.(createdComment);
                })
                .catch(() => {
                  onCreateCommentError?.();
                  showToast(
                    toastTypes.ERROR,
                    'There was an error creating the comment. Try again.',
                  );
                  deleteComment(newEditorComment.commentId);
                })
                .finally(() => {
                  hide();
                });
            }
          }}
        />
      ),
    }),
  ];

  const isContextMenuEnabled = !!contextMenuOptions.length;

  const convertedFile: Nullable<File> = useMemo(() => {
    if (isComparing && comparingDocument) {
      return convertSfdtToFile(comparingDocument);
    } else if (documentSfdt) {
      return convertSfdtToFile(documentSfdt);
    }
    return null;
  }, [documentSfdt, comparingDocument && isComparing]);

  function getEditButtonTooltipMessage() {
    if (!isLatestVersion) {
      return 'Only the most recent version can be edited.';
    } else if (!isTicketInEditReviewStage) {
      return 'This document is not in the edit or review stage. Only documents in the edit or review stage can be edited.';
    } else if (isWopiBeingUsed) {
      return 'This document is being edited in Microsoft Word for the web.';
    }
    return isAnotherUserEditing && docEditorUser
      ? `${getUserName(docEditorUser)} is editing the document`
      : undefined;
  }

  const getEditOnlineButton = () => {
    return isWordEditingOnlineEnabled ? (
      // if the document is generated from template, we render a dumb button just to trigger the modal
      isGeneratedFromTemplate || currentVersion.isCounterparty ? (
        <Button
          id="open-online-button"
          text="Edit online"
          icon="chevron-down"
          onClick={() => {
            setModalConfig(
              ModalConfigs[
                isGeneratedFromTemplate ? 'bumpTemplate' : 'bumpCounterparty'
              ],
            );
            showBumpVersionModal();
          }}
          disabled={isEditDisabled || isEj2BeingUsed}
          tooltip={isWopiBeingUsed ? '' : getEditButtonTooltipMessage()}
        />
      ) : (
        <DocumentOnlineEditorButton
          id="open-online-button"
          documentId={documentId}
          documentVersionId={versionId}
          documentProvider="ev"
          disabled={isEditDisabled || isEj2BeingUsed}
          tooltipMessage={isWopiBeingUsed ? '' : getEditButtonTooltipMessage()}
        />
      )
    ) : null;
  };

  const getErrorMessage = () => {
    if (isDocumentSfdtError) {
      if (coerceRtkqError(documentSfdtError)?.response?.status === 400) {
        return "We're unable to open this document. Please use Word on the Web or edit in Word on your computer and upload again.";
      }
      return 'Something went wrong when loading the document. Please refresh and load again or contact your admin.';
    } else if (isCompareDocumentsError) {
      return 'Something went wrong when comparing the documents. Please refresh and load again or contact your admin.';
    }
  };

  const isEditDisabled =
    !isTicketInEditReviewStage ||
    isAnotherUserEditing ||
    !isLatestVersion ||
    isEditButtonDisabled ||
    !isEditLayoutView;

  return (
    <ContentContainer
      loadingContent={{
        isLoading: isLoadingDocumentSfdt,
        description: loadingMessage,
      }}
      placeholderContent={
        isDocumentSfdtError || isCompareDocumentsError
          ? {
              icon: 'status-danger',
              message: getErrorMessage(),
            }
          : undefined
      }
    >
      {!isLoadingDocumentSfdt ? (
        <>
          <EJ2DocumentEditor
            readonly={
              isComparing || !isEditLayoutView || !isLatestVersion || isReadOnly
            }
            width={`calc(100vw - ${sidebarWidth})`}
            file={convertedFile!}
            onDocumentSave={onDocumentSave}
            isEditButtonDisabled={
              isEditDisabled || isWopitestType || isWopiBeingUsed
            }
            activeHighlightId={String(activeHighlightId)}
            editButtonTooltip={getEditButtonTooltipMessage()}
            editOnlineButton={getEditOnlineButton()}
            onEditClick={onDocEditorEditButtonClick}
            isEditing={isCurrentUserEditing && !isComparing}
            onContentChange={onContentChange}
            onDocumentLoad={onDocumentLoadHandler}
            onBeforeDocumentLoad={onBeforeDocumentLoad}
            highlights={highlights || []}
            contentResolver={contentResolver}
          />
          {userInactiveModal}
          {bumpVersionModal}
          {isContextMenuEnabled && !isComparing && isEditLayoutView && (
            <ContextMenu
              options={contextMenuOptions || []}
              parentWidth={sidebarWidth}
              onSelectionFinish={onSelectionFinish}
              onOpen={onContextMenuOpen}
            />
          )}
        </>
      ) : (
        <></>
      )}
    </ContentContainer>
  );
}
