import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { EVISORT_URL_REGEXP } from '~/constants/regexp';
import {
  Actions,
  Feedback,
  formatDate,
  getUserName,
  Icon,
  Layout,
  LoadingShimmer,
  Markdown,
  Tooltip,
  types,
} from '~/eds';
import { api } from '~/redux';
import { chatbotSlice } from '~/redux/slices/chatbot';
import { getApplicationRoute, useRouting } from '~/routing';

import { Citation, FeedbackDetails, Message as MessageType } from '../types';
import { BlinkingCursor } from './BlinkingCursor';
import { SourcesList } from './components/Sources/SourcesList';
import { CHOICE_DISPLAY_LENGTH, MESSAGE_ACTIONS_CLASSNAME } from './constants';
import { ThinkingEvents } from './ThinkingEvents';
import { createMarkdownComponents, testIsTicketCitation } from './utils';

interface Props {
  message: MessageType;
  user: types.User;
  onChoose: (message: MessageType, choice: string) => void;
  onSubmitFeedback: (
    message: MessageType,
    feedbackDetails: FeedbackDetails,
  ) => void;
  onSelectSource?: (message: MessageType, sourceIndex: number) => void;
  disableChoices?: boolean;
  isLoading?: boolean;
  isReplying?: boolean;
  messageActions: types.UserAction[];
  thinkingEvents?: string[];
  shouldShowActions: boolean;
}

export const AiMessage = ({
  disableChoices,
  isLoading = false,
  isReplying = false,
  message,
  messageActions,
  thinkingEvents,
  shouldShowActions,
  user,
  onChoose,
  onSelectSource,
  onSubmitFeedback,
}: Props) => {
  const { location, navigate } = useRouting();
  const dispatch = useDispatch();
  const [resolveCitation] = api.endpoints.resolveCitation.useLazyQuery();
  const handleSubmitFeedback = (value: types.Nullable<boolean>) => {
    if (message) {
      onSubmitFeedback(message, { value });
    }
  };
  const { choices = [], text, timestamp, citations = [] } = message ?? {};
  const tooltip = `${getUserName(user)}\n${formatDate(timestamp, 'full')}`;

  const messageChoices = useMemo(
    () =>
      (message?.choices ?? []).map((choice) => {
        /** TODO - `Button` should handle this truncation internally after design proposes the display length limit */
        const isLongText = choice.length > CHOICE_DISPLAY_LENGTH;
        return {
          disabled: disableChoices,
          level: 'secondary' as const,
          text: isLongText
            ? choice.substring(0, CHOICE_DISPLAY_LENGTH) + '…'
            : choice,
          tooltip: isLongText ? choice : undefined,
          onClick: () => {
            if (message) {
              onChoose(message, choice);
            }
          },
        };
      }),
    [disableChoices, message],
  );

  const handleTicketEntityCitationClick = async (citation: Citation) => {
    const resolvedCitation = await resolveCitation(citation.entity).unwrap();
    if (resolvedCitation) {
      handleCitationLinkAndHighlights({
        ...citation,
        link: resolvedCitation.link,
      });
    }
  };

  const handleCitationLinkAndHighlights = (citation: Citation) => {
    if (citation?.link) {
      dispatch(chatbotSlice.actions.setSources([...citation.highlights]));
      dispatch(chatbotSlice.actions.setActiveSourceIndex(0));
      const destinationRoute = testIsTicketCitation(citation)
        ? citation.link
        : getApplicationRoute(citation.link);

      if (location.pathname !== destinationRoute) {
        navigate(destinationRoute);
      }
    }
  };

  const handleCitationClick = (citation: Citation) => {
    if (testIsTicketCitation(citation)) {
      handleTicketEntityCitationClick(citation);
    } else {
      handleCitationLinkAndHighlights(citation);
    }
  };

  const hasCitations = citations.length > 0;

  const markdownComponents = useMemo(
    () =>
      createMarkdownComponents(
        {
          citations: citations,
          onCitationClick: handleCitationClick,
          isReplying,
        },
        {
          shouldSkipLinks: hasCitations,
        },
      ),
    [hasCitations],
  );

  const markdownOptions = useMemo(
    () => ({
      allowLinks: {
        disabledTooltip:
          'This link is to an external source that cannot be verified by Evisort.  Please manually verify the link before copy and pasting into your web browser.',
        regexp: EVISORT_URL_REGEXP,
      },
    }),
    [],
  );

  return (
    <Layout direction="column" spacing={4} styles={componentStyles.message}>
      <Layout align="center">
        <Layout ml="-3px" alignSelf="flex-start">
          <Tooltip tooltip={tooltip} placement="left">
            <Icon
              name="evisort-ai-animated"
              size="ml"
              icon="evisort-ai-animated"
            />
          </Tooltip>
        </Layout>
        <Layout pl="13px" direction="column" w="100%">
          {!!thinkingEvents?.length && (
            <ThinkingEvents events={thinkingEvents} />
          )}
          {isLoading ? (
            <Layout direction="column" py={2} pr={2} spacing={1}>
              <LoadingShimmer height="20px" width="100%" />
              <LoadingShimmer height="20px" width="90%" />
              <LoadingShimmer height="20px" width="80%" />
            </Layout>
          ) : (
            <Markdown
              components={markdownComponents}
              options={markdownOptions}
              text={text}
            />
          )}
        </Layout>
      </Layout>
      {isReplying && !isLoading && Boolean(text) && (
        <Layout>
          <BlinkingCursor />
        </Layout>
      )}

      <Layout
        direction="column"
        flex="auto"
        justify="center"
        minW={0}
        position="relative"
        spacing={2}
      >
        {choices && <Actions wrap actions={messageChoices} size="s" />}
      </Layout>
      <SourcesList
        message={message}
        onSelectSource={onSelectSource}
        onSelectCitation={handleCitationClick}
      />
      {shouldShowActions && (
        <Layout
          bg="background"
          borderRadius={4}
          className={MESSAGE_ACTIONS_CLASSNAME}
          styles={componentStyles.actions}
        >
          <Actions actions={messageActions} />
          {Boolean(messageActions.length) && (
            <Feedback
              value={message?.feedbackDetails?.value ?? null}
              onChange={handleSubmitFeedback}
            />
          )}
        </Layout>
      )}
    </Layout>
  );
};

const componentStyles = {
  message: {
    position: 'relative',
    [`&:hover .${MESSAGE_ACTIONS_CLASSNAME}`]: {
      visibility: 'visible',
    },
    [`&.current:hover + .other .${MESSAGE_ACTIONS_CLASSNAME}`]: {
      visibility: 'visible',
    },
    paddingTop: 1,
  },
  actions: {
    boxShadow: 'raised',
    bottom: 0,
    position: 'absolute',
    right: 4,
    transform: 'translateY(-10%)',
    visibility: 'hidden',
  },
};
