import React, { useMemo } from 'react';

import { useToggle } from '../../hooks';
import { Input, Nullable, Option } from '../../types';
import { testHasTextMatch, typedMemo } from '../../utils';
import { Box } from '../Box';
import { Checkbox } from '../Checkbox';
import { Chip } from '../Chip';
import { Grid } from '../Grid';
import { Icon } from '../Icon';
import { IconButton } from '../IconButton';
import { Layout } from '../Layout';
import { Markdown } from '../Markdown';

export type CheckboxGroupProps<V> = Input<V, true> & {
  /** Checkbox options */
  options: Option<V>[];
  /** Group columns */
  columns?: number;
  /** CheckboxGroup collapsible content */
  collapsible?: boolean;
  /** Disables labels background */
  disableGroupBackground?: boolean;
  /** Display the groups count and the selected options count */
  enableCount?: boolean;
  /** Group info tooltip */
  info?: string;
  /** Group label */
  label?: string;
  /** Pinned values */
  pins?: V[];
  /** When option.label matches search, render a text match */
  search?: string;
  /** Callback when option label is clicked */
  onOptionClick?: (option: Option<V>) => void;
  /** Enables pin features when callback is provided */
  onUpdatePins?: (
    updatedPins: V[],
    action: { value: V; type: 'pin' | 'unpin' },
  ) => void;
  /** CheckboxGroup mode */
  mode?: 'descriptive';
};

type GroupValues<V> = Nullable<V[]>;

type CheckedValue = Nullable<boolean>;

/**
 * `CheckboxGroup` provides a way to render and use a group of checkboxes.  It has a similar API as `Checkbox` with small props differences (`value`, `onChange`, `options`). `CheckboxGroup` is also implemented with accessibility in mind, rendering as a `HTMLFieldSetElement` of options with an appropriate `HTMLFieldElement` group label.
 */
export const CheckboxGroup = typedMemo(
  <V extends unknown>({
    columns = 1,
    collapsible = false,
    disabled,
    disableGroupBackground = false,
    enableCount,
    info,
    label,
    mode,
    name,
    options,
    pins = [],
    search,
    value = [],
    onChange,
    onOptionClick,
    onUpdatePins,
  }: CheckboxGroupProps<V>) => {
    const enabledValues = useMemo(() => {
      return options
        .filter((option) => !option.disabled)
        .map((option) => option.value);
    }, [options]);

    const enablePins = Boolean(onUpdatePins);

    const pinnedValueSet = useMemo(() => new Set(pins), [pins]);

    const isDescriptive = mode === 'descriptive';

    const [isCollapsed, toggleIsCollapsed] = useToggle();

    if (options.length === 0) {
      return null;
    }

    const handleSingleValueChange = (v: V) => (
      updatedChecked: CheckedValue,
    ) => {
      let updatedValue = null;
      if (value === null) {
        updatedValue = updatedChecked ? [v] : [];
      } else {
        updatedValue = updatedChecked
          ? [...value, v]
          : value.filter((v2) => v2 !== v);
      }

      onChange(updatedValue);
    };

    const handleGroupValueChange = (updatedGroupValue: CheckedValue) => {
      const updatedValue = updatedGroupValue ? enabledValues : [];
      onChange(updatedValue);
    };

    const handleUpdatePins = (optionValue: V) => () => {
      if (enablePins) {
        const isUnpin = pinnedValueSet.has(optionValue);
        const updatedPins = isUnpin
          ? pins.filter((pin) => pin !== optionValue)
          : [...pins, optionValue];

        onUpdatePins?.(updatedPins, {
          value: optionValue,
          type: isUnpin ? 'unpin' : 'pin',
        });

        if (!isUnpin) {
          if (!value?.includes(optionValue)) {
            handleSingleValueChange(optionValue)(true);
          }
        } else {
          if (value?.includes(optionValue)) {
            handleSingleValueChange(optionValue)(false);
          }
        }
      }
    };

    const groupValue = getGroupValue(value, enabledValues);

    const filteredOptions = search
      ? options.filter((option) => testHasTextMatch(option.label, search))
      : options;

    const optionsCount = filteredOptions.length;
    const selectedCount = `${value?.length} of ${optionsCount} Selected`;

    const legend = label && (
      <Layout
        as="legend"
        styles={componentStyles.legend}
        bg={disableGroupBackground ? null : 'background.quiet'}
        align="center"
        justify="space-between"
      >
        <Checkbox
          disabled={disabled}
          labelVariant="body-bold"
          count={enableCount ? optionsCount : undefined}
          name={`${name}-group`}
          option={{ info, label, value: groupValue }}
          value={groupValue}
          onChange={handleGroupValueChange}
        />
        <Layout align="center" justify="flex-end" spacing={2}>
          {enableCount && <Chip text={selectedCount} mode="tag" />}
          {collapsible && (
            <IconButton
              size="s"
              onClick={toggleIsCollapsed}
              preset={isCollapsed ? 'hide' : 'show'}
            />
          )}
        </Layout>
      </Layout>
    );

    return search && filteredOptions.length === 0 ? null : (
      <Layout
        as="fieldset"
        direction="column"
        spacing={2}
        styles={componentStyles.fieldset}
      >
        {legend}
        <Grid
          columns={isDescriptive ? 2 : columns}
          columnSpacing={6}
          pl={label ? 4 : undefined}
          rowSpacing={2}
        >
          {filteredOptions.map((option, i) => {
            const { disabled: optionDisabled, value: optionValue } = option;
            const isOptionPinned = pinnedValueSet.has(optionValue);
            return (
              <React.Fragment key={i}>
                <Layout
                  align="baseline"
                  justify="space-between"
                  styles={componentStyles.option}
                  display={isCollapsed ? 'none' : undefined}
                >
                  <Checkbox
                    disabled={disabled || optionDisabled}
                    name={name}
                    option={option}
                    search={search}
                    value={value?.includes(optionValue) || false}
                    onChange={handleSingleValueChange(optionValue)}
                    onOptionClick={onOptionClick}
                  />
                  {enablePins && (
                    <Pin
                      isPinned={isOptionPinned}
                      onUpdatePin={handleUpdatePins(optionValue)}
                    />
                  )}
                </Layout>
                {isDescriptive && (
                  <Markdown
                    preset="description"
                    text={option.description ?? ''}
                  />
                )}
              </React.Fragment>
            );
          })}
        </Grid>
      </Layout>
    );
  },
);

interface PinProps {
  isPinned: boolean;
  onUpdatePin: () => void;
}

const Pin = ({ isPinned, onUpdatePin }: PinProps) => {
  return (
    <Box
      className="pin"
      styles={[componentStyles.pin, isPinned ? null : componentStyles.hidden]}
    >
      <Icon
        onClick={onUpdatePin}
        icon={isPinned ? 'push-pin-filled' : 'push-pin'}
        color="status.inactive"
      />
    </Box>
  );
};

export const getGroupValue = <V extends unknown>(
  values: GroupValues<V>,
  enabledValues: V[] = [],
): CheckedValue => {
  if (values === null || values.length === 0) {
    return false;
  } else if (values.length === enabledValues.length) {
    return true;
  } else {
    return null;
  }
};

const componentStyles = {
  fieldset: {
    border: 'none',
    padding: 'unset',
  },
  legend: {
    borderRadius: 'm',
    paddingBottom: 2,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 2,
    width: '100%',
  },
  option: {
    ':hover .pin': {
      visibility: 'visible',
    },
  },
  pin: {
    display: 'flex',
    marginRight: 1,
  },
  hidden: {
    visibility: 'hidden',
  },
};
