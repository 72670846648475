import React, { useState } from 'react';

import {
  ContentContainer,
  Layout,
  PageLayout,
  Section,
  Table,
  useToast,
} from '~/eds';
import {
  AddPartyModal,
  BasePartyModalProps,
  DeletePartyModal,
  ExtendedPartyModalProps,
  PAGE_INDEX,
  PaginationParams,
  Party,
  PartyModalProps,
  TABLE_OPTIONS,
  UpdatePartyModal,
  UploadPartyModal,
} from '~/features/manage-parties';
import { FlagType, useFlag } from '~/flags';
import { api } from '~/redux';
import { generateNav, RoutePathType, useRouting } from '~/routing';
import { Nullable } from '~/types';

import DEPRECATED_ManagePartyListsPage from './DEPRECATED_index';

interface DeletedParty {
  status: 'deleted' | 'failed';
  party: Party;
}

const Page = () => {
  const enableManagePartyListsPage = useFlag(FlagType.ManagePartyListsPageNew);
  const { params } = useRouting();

  const clientId = Number(params.clientId);

  const { toast } = useToast();

  const nav = generateNav({
    current: {
      text: 'Manage Party Lists',
      pathname: RoutePathType.AdminConsoleClientManagePartyLists,
    },
    from: RoutePathType.AdminConsoleClient,
    params: {
      clientId,
    },
  });

  const [modalProps, setModalProps] = useState<Nullable<PartyModalProps>>(null);
  const [internalPage, setInternalPage] = useState(PAGE_INDEX);
  const [externalPage, setExternalPage] = useState(PAGE_INDEX);

  const {
    data: externalParties = { results: [], count: 0 },
    isFetching: isFetchingExternalParties,
    error: externalPartiesError,
  } = api.endpoints.getParties.useQuery(
    {
      clientId,
      isInternal: false,
      page: externalPage,
    },
    { skip: !clientId },
  );

  const {
    data: internalParties = { results: [], count: 0 },
    isFetching: isFetchingInternalParties,
    error: internalPartiesError,
  } = api.endpoints.getParties.useQuery(
    {
      clientId,
      isInternal: true,
      page: internalPage,
    },
    { skip: !clientId },
  );

  const [deleteParties] = api.endpoints.deleteParties.useMutation();

  const handleHideModal = () => {
    setModalProps(null);
  };

  if (!enableManagePartyListsPage) {
    // eslint-disable-next-line react/jsx-pascal-case -- deprecating
    return <DEPRECATED_ManagePartyListsPage />;
  }

  const columns = [
    {
      key: 'name',
      cellType: 'text',
      title: 'Party Name',
      mapCellProps: (d: Party) => ({
        text: d.name,
      }),
      width: 'auto',
    },
  ];

  const rowActions = [
    {
      label: 'Edit Party',
      onClick: (party: Party) => {
        setModalProps({
          action: 'edit',
          clientId,
          party,
          onHide: handleHideModal,
        });
      },
    },
    {
      label: 'Delete Party',
      onClick: (party: Party) => {
        setModalProps({
          action: 'delete',
          clientId,
          party,
          onHide: handleHideModal,
        });
      },
    },
  ];

  const getBulkActions = () => {
    return [
      {
        label: 'Delete',
        onClick: (selectedParties: any) => {
          const { data: parties, name } = selectedParties;
          const isInternal = name === 'Internal Party List';
          deleteParties({ parties, clientId, isInternal })
            .unwrap()
            .then((deletedParties: DeletedParty[]) => {
              const deletedPartyNames = deletedParties
                .filter((party) => party.status === 'deleted')
                .map((item) => item.party.name)
                .join(', ');

              const failedPartyNames = deletedParties
                .filter((party) => party.status === 'failed')
                .map((item) => item.party.name)
                .join(', ');
              if (deletedPartyNames) {
                toast({
                  message: `Parties "${deletedPartyNames}" have been successfully removed.`,
                  status: 'success',
                });
              }
              if (failedPartyNames) {
                toast({
                  message: `Failed to remove parties "${failedPartyNames}".`,
                  status: 'danger',
                });
              }
            })
            .catch((error) => {
              toast({
                message: `${error.response.data.detail}`,
                status: 'danger',
              });
            });
        },
      },
    ];
  };

  const children = (
    <Layout preset="sections">
      {[true, false].map((isInternal) => {
        const partyConfigs = isInternal
          ? {
              data: internalParties,
              isFetchingParties: isFetchingInternalParties,
              placeholderContent: internalPartiesError
                ? { message: 'Failed to load party lists, try again.' }
                : internalParties.count === 0
                ? { message: 'No Party Lists found.' }
                : undefined,
              title: 'Internal Party List',
              setPage: setInternalPage,
              state: {
                pageIndex: internalPage,
              },
            }
          : {
              data: externalParties,
              isFetchingParties: isFetchingExternalParties,
              placeholderContent: externalPartiesError
                ? { message: 'Failed to load party lists, try again.' }
                : externalParties.count === 0
                ? { message: 'No Party Lists found.' }
                : undefined,
              title: 'External Party List',
              setPage: setExternalPage,
              state: {
                pageIndex: externalPage,
              },
            };

        return (
          <Section key={partyConfigs.title} title={partyConfigs.title}>
            <ContentContainer
              placeholderContent={partyConfigs.placeholderContent}
            >
              <Table
                data={partyConfigs.data.results}
                columns={columns}
                isLoading={partyConfigs.isFetchingParties}
                name={partyConfigs.title}
                options={TABLE_OPTIONS}
                getBulkActions={getBulkActions}
                rowActions={rowActions}
                totalCount={partyConfigs.data.count}
                state={partyConfigs.state}
                onPaginate={({ pageIndex }: PaginationParams) => {
                  partyConfigs.setPage(pageIndex);
                }}
              />
            </ContentContainer>
          </Section>
        );
      })}

      {modalProps?.action === 'edit' ? (
        <UpdatePartyModal {...(modalProps as ExtendedPartyModalProps)} />
      ) : null}
      {modalProps?.action === 'delete' ? (
        <DeletePartyModal {...(modalProps as ExtendedPartyModalProps)} />
      ) : null}
      {modalProps?.action === 'add' ? (
        <AddPartyModal {...(modalProps as BasePartyModalProps)} />
      ) : null}
      {modalProps?.action === 'upload' ? (
        <UploadPartyModal {...(modalProps as BasePartyModalProps)} />
      ) : null}
    </Layout>
  );

  return (
    <PageLayout
      actions={[
        {
          onClick: () => {
            setModalProps({
              action: 'add',
              clientId,
              onHide: handleHideModal,
            });
          },
          text: 'Add New Party',
        },
        {
          level: 'primary',
          onClick: () => {
            setModalProps({
              action: 'upload',
              clientId,
              onHide: handleHideModal,
            });
          },
          text: 'Upload',
        },
      ]}
      loadingContent={{
        isLoading: isFetchingExternalParties || isFetchingInternalParties,
        message: 'Loading party lists...',
      }}
      children={children}
      nav={nav}
      title="Manage Party Lists"
    />
  );
};

export default Page;
