import { Box, Button } from '~/eds';

interface Props {
  text: string;
  onClick: () => void;
}

export const InlineCitation = ({ text, onClick }: Props) => {
  return (
    <Box display="inline-block">
      <Button text={text} onClick={onClick} size="xs" />
    </Box>
  );
};
