import { saveAs } from 'file-saver';
import React, { useMemo } from 'react';

import { trackSegment } from '~/components/SegmentAnalytics';
import { Panel_DEPRECATED, types, useToast } from '~/eds';
import { FeatureFlagType } from '~/enums';
import { useClientSubDomain, useCurrentUser } from '~/hooks';
import { testHasFlag } from '~/permissions';
import { api } from '~/redux';
import { PilotId, SearchTextMatch } from '~/types';
import { openInNewTab } from '~/utils/browser';

import Clauses from './Clauses/Clauses';
import { Fields } from './Fields';
import { SearchMatches } from './SearchMatches';

type ActionConfigs = {
  onClick?: () => void;
  disabled?: boolean;
  hidden?: boolean;
};

interface Props {
  actionsConfig?: {
    /** flag to override configure download action. */
    download?: ActionConfigs;
    /** Flag to indicate if navigation to document is available */
    navigate?: ActionConfigs;
    /** callback to show the next document. If not provided, action will be hidden */
    next?: ActionConfigs;
    /** callback to next document action. If not provided, action will be hidden */
    previous?: ActionConfigs;
  };
  /** When provided, will enable the Keyword Matches tab. */
  searchKeyworkMatches?: SearchTextMatch;
  /** When provided, will expand the accordion item of the clause and highlight the title */
  featuredClauses?: string[];
  /** document handler id */
  docHandlerId?: PilotId;
  /** Panel title */
  documentName?: string;
  /** necessary for download document action.  */
  documentFormatType?: string;
  /** callback will be called when hiding the panel.*/
  onHide: () => void;
}

const enabledByDefault: ActionConfigs = { hidden: false };

export const DocumentDetailsPanel = ({
  actionsConfig,
  documentFormatType,
  docHandlerId,
  searchKeyworkMatches,
  documentName = '',
  featuredClauses,
  onHide,
}: Props) => {
  const {
    download = enabledByDefault,
    navigate = enabledByDefault,
    next,
    previous,
  } = actionsConfig ?? {};
  const client = useClientSubDomain();
  const currentUser = useCurrentUser();
  const hasEditClauseFlag = testHasFlag(FeatureFlagType.EditClause)(
    currentUser,
  );
  const { toast } = useToast();
  api.endpoints.getDocumentInformation.useQuery(docHandlerId!, {
    skip: !docHandlerId,
  });

  const endpoint = hasEditClauseFlag
    ? 'getAllProvisionsGrouped'
    : 'getDocumentDetailClauses';

  api.endpoints[endpoint].useQuery(
    hasEditClauseFlag
      ? { docId: docHandlerId!, page: 1, pageSize: 100 }
      : ({ documentId: docHandlerId! } as any),
    {
      skip: !docHandlerId,
    },
  );
  const [
    getDocumentOriginal,
  ] = api.endpoints.getDocumentOriginal.useLazyQuery();

  const searchMatches = useMemo(() => {
    if (searchKeyworkMatches) {
      return searchKeyworkMatches.map((item) => ({
        content: [item],
        name: '',
      }));
    }
    return null;
  }, [searchKeyworkMatches]);

  async function defaultOnDownloadClick() {
    if (docHandlerId) {
      try {
        const doc = await getDocumentOriginal(docHandlerId).unwrap();
        if (doc) {
          saveAs(doc, `${documentName}.${documentFormatType}`);
          trackSegment('selectDownloadDocument', {
            name: docHandlerId,
          });
        }
      } catch {
        toast({
          status: 'danger',
          message: `Something went wrong with downloading ${documentName}`,
        });
      }
    }
  }

  function defaultOnNavigateToDocClick() {
    openInNewTab(`/${client}/document/${docHandlerId}`);
    trackSegment('selectDocument', {
      name: docHandlerId,
    });
  }

  const tabs = [
    {
      label: 'Fields',
      value: 'fields',
      panel: <Fields docHandlerId={docHandlerId} />,
    },
    {
      label: 'Clauses',
      value: 'clauses',
      panel: (
        <Clauses
          docHandlerId={docHandlerId}
          featuredClauses={featuredClauses}
        />
      ),
    },
    ...(searchMatches
      ? [
          {
            label: 'Keyword Matches',
            value: 'matches',
            panel: <SearchMatches matches={searchMatches!} />,
          },
        ]
      : []),
  ];

  const actions: types.Action[] = [
    ...(!navigate.hidden
      ? [
          {
            icon: 'open-in-new' as const,
            label: 'Open Document',
            value: 'Open Document',
            onClick: navigate.onClick ?? defaultOnNavigateToDocClick,
            disabled: navigate.disabled,
          },
        ]
      : []),
    ...(!download.hidden
      ? [
          {
            icon: 'download' as const,
            label: 'Download',
            value: 'Download',
            onClick: download.onClick ?? defaultOnDownloadClick,
            disabled: download.disabled,
          },
        ]
      : []),
    ...(previous && !previous.hidden
      ? [
          {
            disabled: previous.disabled,
            icon: 'chevron-left' as const,
            label: 'Previous',
            value: 'Previous',
            onClick: () => {
              previous.onClick?.();
              trackSegment('selectPreviousDocument', {
                name: docHandlerId,
              });
            },
          },
        ]
      : []),
    ...(next && !next.hidden
      ? [
          {
            disabled: next.disabled,
            icon: 'chevron-right' as const,
            label: 'Next',
            value: 'Next',
            onClick: () => {
              next.onClick?.();
              trackSegment('selectNextDocument', {
                name: docHandlerId,
              });
            },
          },
        ]
      : []),
  ];

  return (
    // eslint-disable-next-line react/jsx-pascal-case -- deprecating
    <Panel_DEPRECATED
      actions={actions}
      isVisible={Boolean(docHandlerId)}
      onHide={onHide}
      title={documentName}
      tabs={tabs}
      width="l"
      isPadded
    />
  );
};

export default DocumentDetailsPanel;
