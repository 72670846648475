import { createSelector } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

import { SliceType } from '~/enums';
import { ClauseValue, Filter } from '~/evifields';

import { isActiveFilter } from '../slices/search';
import { SearchV3Slice } from '../slices/searchV3';

const slice = (rootState: any): SearchV3Slice => rootState[SliceType.SearchV3];

const _selectColumnOrder = createSelector(slice, (state) => state.columnOrder);
export const selectColumnOrder = createSelector(
  _selectColumnOrder,
  (columnOrder) => columnOrder.map(String),
);
export const selectColumnWidths = createSelector(
  slice,
  (state) => state.columnWidths,
);
export const selectFieldValues = createSelector(
  slice,
  (state) => state.fieldValues,
);
export const selectPage = createSelector(slice, (state) => state.page);
export const selectPageSize = createSelector(slice, (state) => state.pageSize);
export const selectSearchText = createSelector(
  slice,
  (state) => state.searchText,
);

export const selectQueryBuilder = createSelector(
  slice,
  (state) => state.queryBuilder,
);
export const selectQueryBuilderPanelVisible = createSelector(
  slice,
  (state) => state.queryBuilderPanelVisible,
);
export const selectQueryFields = createSelector(
  slice,
  (state) => state.queryFields,
);
export const selectSelectedFilters = createSelector(
  slice,
  (state) => state.selectedFilters,
);
export const selectActiveFilters = createSelector(
  selectSelectedFilters,
  (filters) => filters.filter(isActiveFilter),
);

export const selectIsInitialized = createSelector(
  slice,
  (state) => state.searchText !== undefined,
);
export const selectSavedSearch = createSelector(
  slice,
  (state) => state.savedSearch,
);

export const selectInitialValues = createSelector(
  slice,
  (state) => state.initialValues,
);

export const selectFeaturedClauses = createSelector(
  selectActiveFilters,
  (filters) =>
    (filters.filter(
      (filter) =>
        filter.fieldId === 'clause' && filter.operatorId === 'contains_any',
    ) as Filter<ClauseValue>[]).map(
      (clauseFilter) => clauseFilter.values[0]?.provision ?? '',
    ),
);

export const selectIsSearchDirty = createSelector(
  selectInitialValues,
  selectQueryBuilder,
  selectSelectedFilters,
  selectSearchText,
  selectColumnOrder,
  (initialValues, queryBuilder, selectedFilters, searchText, columnOrder) => {
    if (!initialValues) return false;
    return !isEqual(initialValues, {
      columns: columnOrder,
      queryBuilder: queryBuilder,
      selectedFilters: selectedFilters,
      searchText: searchText!,
    });
  },
);

export const selectSortBy = createSelector(slice, (state) => state.sortBy);

export const selectResultsCache = createSelector(
  slice,
  (state) => state.searchResultCache,
);
