import { Endpoint } from '~/features/webhooks';
import { courier } from '~/services';

interface Request {
  endpoint: Endpoint;
}

export const deleteEndpoint = async ({
  endpoint,
}: Request): Promise<boolean> => {
  const response = await courier.remove(`/endpoints/${endpoint.id}`);
  return Boolean(response);
};
