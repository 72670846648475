import { Attempt } from '~/features/webhooks';
import { courier } from '~/services';

interface Request {
  attempt: Attempt;
}

export const deleteAttempt = async ({ attempt }: Request): Promise<boolean> => {
  const response = await courier.remove(`/delivery-attempts/${attempt.id}`);
  return Boolean(response);
};
