import { noop } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Layout,
  Modal,
  scrollElementIntoView,
  StatusMessage,
  Text,
  useModal,
  useToast,
  useUuid,
} from '~/eds';
import { CreditBalance } from '~/features/billing';
import { FlagType, useFlag } from '~/flags';
import { useClientId } from '~/hooks';
import { actions, api, selectors, slices } from '~/redux';
import { RoutePathType, useRouting } from '~/routing';

import {
  calculateCreditEstimate,
  getCreditsTooltip,
  getEstimatedCreditsTooltip,
} from '../billing';
import { PUBLISH_ELEMENT_ID } from '../constants';
import { FieldModel } from '../fields/types';
import { ReviewPublish } from '../Review';
import { testIsNonOptimizedModel } from '../utils';

interface Props {
  isVisible: boolean;
  modelVersion: FieldModel;
  onCancel: () => void;
}

export const PublishModal = ({ isVisible, modelVersion, onCancel }: Props) => {
  const [id] = useUuid();
  const [isPublishBtnDisabled, setIsPublishBtndisabled] = useState(true);
  const dispatch = useDispatch();
  const { navigate } = useRouting();
  const { toast } = useToast();
  const enableDevRefactor = useFlag(FlagType.DocumentXRayDevListPageRefactor);
  const isDocumentXRayBilling = useFlag(FlagType.DocumentXRayBilling);
  const clientId = useClientId();

  const editModelConfirmed = testIsNonOptimizedModel(modelVersion, [
    'suspended',
  ]);

  const [
    publishFieldModel,
    { isLoading: isPublishingFieldModel },
  ] = api.endpoints.publishPromptModel.useMutation();

  const {
    data: progress = {
      docsProcessed: 0,
      docsInScope: 0,
    },
    isLoading,
  } = api.endpoints.getPromptModelProgress.useQueryState({
    modelId: modelVersion.id!,
  });

  const selectPublishMode = useSelector(selectors.selectFieldAiPublishMode);
  const creditSummary = useSelector(selectors.selectFieldAiCreditsSummary);
  const { docsInScope, docsProcessed } = progress;
  const newDocsInScope = calculateCreditEstimate(docsProcessed, docsInScope);

  const credits =
    selectPublishMode === 'new_docs_in_scope' ? newDocsInScope : docsInScope;

  // rescroll back to top if prior scroll effects were triggered
  useEffect(
    () =>
      scrollElementIntoView(PUBLISH_ELEMENT_ID, {
        behavior: 'smooth',
      }),
    [],
  );

  const handlePublish = () => {
    publishFieldModel({
      modelId: modelVersion.id,
      version: modelVersion.version,
      idempotencyKey: id,
      publishMode: selectPublishMode,
    })
      .unwrap()
      .then(
        () => {
          toast({
            message: `**${
              modelVersion!.name
            }** is active and is processing documents.`,
            status: 'success',
          });
          if (enableDevRefactor) {
            dispatch(actions.resetFieldAi());
            dispatch(
              slices.xRay.actions.setActiveModelResultInfo({
                id: modelVersion.id,
                latestVersion: modelVersion.version,
                targetEntityDetails: {
                  id: '', // not needed
                  label: modelVersion.name,
                  type: 'field',
                },
              }),
            );
          } else {
            // remove this when dev refactor flag is enabled globally
            dispatch(
              actions.setFieldAiActiveModel({
                ...modelVersion,
                publishStatus: 'in_progress',
              }),
            );
          }
          navigate(`${RoutePathType.AutomationHubFields}`);
        },
        () =>
          toast({
            message:
              'There was an error publishing the model. Please try again.',
            status: 'danger',
          }),
      );
  };

  const [publishConfirmationModal, showPublishConfirmationModal] = useModal({
    title: 'Confirm to Publish',
    children: (
      <Layout direction="column" spacing={4}>
        <Text>
          Publishing this model will consume the estimated credits shown below.
          Please confirm to proceed.
        </Text>
        <CreditBalance
          clientId={clientId}
          estimateUnit={{
            modelId: modelVersion.id,
            isLoadingUnitValue: isLoading,
            unit: 'document-count',
            value: credits,
          }}
          getCreditsTooltip={getCreditsTooltip}
          getEstimatedCreditsTooltip={getEstimatedCreditsTooltip}
        />
      </Layout>
    ),
    primaryAction: {
      isLoading: isPublishingFieldModel || isPublishBtnDisabled,
      text: 'Confirm',
      onClick: handlePublish,
    },
    onCancel: () => setIsPublishBtndisabled(true),
  });

  const handlePublishConfirmation = () => {
    showPublishConfirmationModal();
    setTimeout(() => {
      setIsPublishBtndisabled(false);
    }, 3000);
  };

  const insufficientCredits =
    editModelConfirmed && creditSummary && creditSummary.credits < credits;

  const primaryAction = {
    disabled: Boolean(insufficientCredits),
    tooltip: insufficientCredits
      ? 'You do not have enough credits to publish.'
      : undefined,
    isLoading: isPublishingFieldModel,
    text: 'Publish',
    onClick: isDocumentXRayBilling ? handlePublishConfirmation : handlePublish,
  };

  return (
    <>
      <Modal
        disableHideOnEscape
        headerCalloutContent={
          isDocumentXRayBilling ? (
            <CreditBalance
              clientId={clientId}
              estimateUnit={
                editModelConfirmed
                  ? undefined
                  : {
                      modelId: modelVersion.id,
                      isLoadingUnitValue: isLoading,
                      unit: 'document-count',
                      value: credits,
                    }
              }
              getCreditsTooltip={getCreditsTooltip}
              getEstimatedCreditsTooltip={getEstimatedCreditsTooltip}
            />
          ) : null
        }
        isFullPage
        isVisible={isVisible}
        primaryAction={primaryAction}
        title="Review & Publish"
        onCancel={onCancel}
      >
        <Layout
          id={PUBLISH_ELEMENT_ID}
          mx="auto"
          preset="sections"
          w="panel.width.l"
        >
          <StatusMessage
            title="Check model overview before publishing"
            message="The Field Model will save the Field Model Configuration details that you were the most recently on.  Once published, the model cannot be modified until it has finished running across the scope of documents you chose."
            status="ai"
            onDismiss={noop}
          />
          <ReviewPublish modelVersion={modelVersion} />
        </Layout>
      </Modal>
      {isDocumentXRayBilling && publishConfirmationModal}
    </>
  );
};
