import { black1, black2, bold } from '../../../assets/shared-styles/general';

const styles = {
  bottomPaginationWrapper: {
    paddingBottom: '10px',
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  partyListTable: {
    width: '100%',
    tableLayout: 'fixed',
    color: black1,
    textAlign: 'left',
    '& thead': {
      '& th': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      },
    },
    '& th': {
      padding: '16px 0',
      color: black2,
      fontSize: '15px',
      fontWeight: bold,
    },
    '& td': {
      padding: '12px 0',
      color: black2,
      fontSize: '15px',
      wordBreak: 'break-all',
    },
  },
  tableSeparator: {
    height: '24px',
  },
};

export default styles;
