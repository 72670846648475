import React from 'react';

import { Select, types } from '~/eds';

import { EnumValue, RenderComponentProps } from './types';

type Props = RenderComponentProps<types.SharedSelectProps<EnumValue, boolean>>;

export const AsyncSelectComponent = ({ asyncProps, innerProps }: Props) => {
  const { asyncOptions, onMount, onQuery } = asyncProps;

  const {
    data: options = [],
    error,
    isFetching,
    selectAllCount,
  } = asyncOptions;

  return (
    <Select
      {...innerProps}
      error={error}
      isLoading={isFetching}
      options={options}
      onMount={onMount}
      onSearch={onQuery}
      selectAllCount={selectAllCount}
    />
  );
};
