import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Layout, PageLayout, Text, useModal, useToast, useUuid } from '~/eds';
import { TableContextType } from '~/enums';
import { CreditBalance, CreditSummary } from '~/features/billing';
import {
  DEPRECATED_ModelModal,
  ModelList,
  ModelVersionInfo,
  usePollModelProgress,
} from '~/features/x-ray';
import {
  calculateCreditEstimate,
  getCreditsTooltip,
  getEstimatedCreditsTooltip,
} from '~/features/x-ray/billing';
import { FlagType, useFlag } from '~/flags';
import { useClientId, useTableSettings } from '~/hooks';
import { actions, api, selectors } from '~/redux';
import { RoutePathType, useRouting } from '~/routing';
import { calcPercentage } from '~/utils';

const Page = () => {
  const [id] = useUuid();
  const { toast } = useToast();
  const isDocumentXRayBilling = useFlag(FlagType.DocumentXRayBilling);

  const clientId = useClientId();

  const dispatch = useDispatch();

  const activeModelVersion = useSelector(selectors.selectFieldAiActiveModel);
  const creditSummary = useSelector(selectors.selectFieldAiCreditsSummary);
  const modelAction = useSelector(selectors.selectFieldAiModelAction);

  const { getSearchParam, navigate } = useRouting();

  const {
    tableSettings = {
      pageSize: 10,
    },
  } = useTableSettings(TableContextType.FieldAi);

  const {
    isLoading: isLoadingProgress,
    progress,
    progressChip,
  } = usePollModelProgress({
    modelId: modelAction?.model.id ?? activeModelVersion?.id,
  });

  const { pageSize } = tableSettings;
  const pageIndex = Number(getSearchParam('pageIndex') ?? 1);

  const {
    data: modelsResult = { models: [], totalCount: 0 },
    isFetching,
    isLoading,
    isError,
  } = api.endpoints.DEPRECATED_getPromptModels.useQuery({
    pageIndex,
    pageSize,
  });

  const [
    publishFieldModel,
    { isLoading: isPublishingFieldModel },
  ] = api.endpoints.publishPromptModel.useMutation();

  const { data: fieldsResult } = api.endpoints.getFields.useQuery(
    {
      ids: modelsResult.models.map((model) => model.config.field.id),
      limit: 1000,
    },
    { skip: !modelsResult.models.length },
  );

  const models = useMemo(() => {
    const { models } = modelsResult;
    if (fieldsResult) {
      const fieldsMap = Object.fromEntries(
        fieldsResult.results.map((field) => [field.id, field]),
      );
      return models.map((model) => {
        const field = fieldsMap[model.config.field.id];
        return {
          ...model,
          name: field?.label ?? model.name, // read from the field if possible, otherwise keep the existing static model name.
        };
      });
    }
    return models;
  }, [modelsResult, fieldsResult]);

  const hasModels = models.length > 0;

  const onCreditSummary = (credit: CreditSummary) =>
    dispatch(actions.setFieldAiCreditsSummary(credit));

  const createAction = {
    level: 'primary' as const,
    text: 'New Field Model',
    onClick: () => {
      dispatch(actions.resetFieldAi());
      navigate(`${RoutePathType.AutomationHubFields}/new`);
    },
  };
  const {
    docsInScope = 0,
    docsInScopeProcessing = false,
    docsProcessed = 0,
    publishStatus = null,
  } = progress ?? {};

  const newDocsInScope = calculateCreditEstimate(docsProcessed, docsInScope);

  const handlePublish = () => {
    if (activeModelVersion) {
      publishFieldModel({
        modelId: activeModelVersion.id,
        version: activeModelVersion.version,
        publishMode: 'new_docs_in_scope',
        idempotencyKey: id,
      })
        .then(() => {
          hidePublishConfirmationModal();
          toast({
            message: `**${activeModelVersion.name}** is active and is processing documents.`,
            status: 'success',
          });
        })
        .catch(() => {
          toast({
            message: 'Failed to publish the model. Please try again.',
            status: 'danger',
          });
        });
    }
  };

  const insufficientCredits =
    creditSummary && creditSummary.credits < newDocsInScope;

  const [
    publishConfirmationModal,
    showPublishConfirmationModal,
    hidePublishConfirmationModal,
  ] = useModal({
    title: 'Confirm to Publish',
    children: (
      <Layout direction="column" spacing={4}>
        <Text>
          Publishing this model will consume the estimated credits shown below.
          Please confirm to proceed.
        </Text>
        <CreditBalance
          clientId={clientId}
          estimateUnit={{
            modelId: activeModelVersion?.id,
            isLoadingUnitValue:
              isLoadingProgress || isLoading || docsInScopeProcessing,
            unit: 'document-count',
            value: newDocsInScope,
          }}
          getCreditsTooltip={getCreditsTooltip}
          getEstimatedCreditsTooltip={getEstimatedCreditsTooltip}
        />
      </Layout>
    ),
    primaryAction: {
      disabled: docsInScopeProcessing || Boolean(insufficientCredits),
      tooltip: docsInScopeProcessing
        ? 'Estimating credits…'
        : insufficientCredits
        ? 'You do not have enough credits to publish.'
        : undefined,
      isLoading: isPublishingFieldModel,
      text: 'Confirm',
      onClick: handlePublish,
    },
    onCancel: () => {
      dispatch(actions.setFieldAiModelAction(null));
      hidePublishConfirmationModal();
    },
  });

  useEffect(() => {
    //this renders again after publish because model action is still updating
    if (modelAction?.action === 'resume' && publishStatus === 'suspended') {
      showPublishConfirmationModal();
    } else {
      hidePublishConfirmationModal();
    }
  }, [modelAction]);

  const panel = activeModelVersion
    ? {
        children: (
          <ModelVersionInfo
            enableTitle
            modelId={activeModelVersion.id}
            version={activeModelVersion.version}
          />
        ),
        chips: [progressChip],
        hidden: {
          isHidden: false,
          onHide: () => dispatch(actions.setFieldAiActiveModel(null)),
        },
        title: activeModelVersion.name,
        width: 'm' as const,
      }
    : undefined;

  const loadingContent = {
    isLoading,
    message: 'Loading field models…',
  };

  const placeholderContent = isError
    ? {
        message:
          'There was an error loading the field models. Refresh the page to try again.',
      }
    : !hasModels
    ? {
        action: createAction,
        image: 'empty-no-groups',
        title: 'Build a Field Model',
        message:
          'Let our AI find the information you are looking for across documents.',
      }
    : undefined;

  const creditUsageWarning = {
    message: 'Contact Evisort to purchase additional credits.',
    status: 'danger' as const,
    title: 'You used more than 90% of your available credits.',
  };

  const depletedCreditWarning = {
    message:
      'Contact Evisort to purchase additional credits. You may be invoiced for the negative credit balance at the Credit overage rate.',
    status: 'danger' as const,
    title: 'You have used up all of your available credits.',
  };

  const creditsUsed = creditSummary
    ? calcPercentage(
        creditSummary.totalCredits - creditSummary.credits,
        creditSummary.totalCredits,
      )
    : 0;

  return (
    <PageLayout
      actions={hasModels ? [createAction] : undefined}
      headerCalloutContent={
        isDocumentXRayBilling && !isLoading ? (
          <CreditBalance
            clientId={clientId}
            getCreditsTooltip={getCreditsTooltip}
            onCreditsSummaryAvailable={onCreditSummary}
          />
        ) : null
      }
      loadingContent={loadingContent}
      panel={panel}
      placeholderContent={placeholderContent}
      statusMessage={
        creditSummary
          ? creditSummary.credits <= 0
            ? depletedCreditWarning
            : creditsUsed && creditsUsed >= 90
            ? creditUsageWarning
            : undefined
          : undefined
      }
      title="Automation Hub"
    >
      <ModelList
        isLoading={isFetching}
        models={models}
        pageIndex={pageIndex}
        totalCount={modelsResult.totalCount}
      />
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <DEPRECATED_ModelModal />
      {publishConfirmationModal}
    </PageLayout>
  );
};

export default Page;
