const styles = {
  workflowTemplatesList: {
    height: '100vh',
    backgroundColor: 'white',
  },
  headerTitle: {
    flexGrow: 1,
    paddingLeft: '32px',
    textAlign: 'center',
  },
  templatesFilter: {
    width: '640px',
  },
  templatesList: {
    width: '640px',
    maxHeight: 'calc(100vh - 156px)',
    borderRadius: '4px',
    border: 'solid 1px rgba(0, 0, 0, 0.1)',
    overflowY: 'scroll',
  },
  listItem: {
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    },
  },
  linkIconButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
};

export default styles;
