import { Layout, Panel, StatusMessage } from '~/eds';
import { Nullable } from '~/types';

import { UploadFile } from './types';
import { mapAlgoStatusMessageProps } from './utils';

interface Props {
  activeFile: Nullable<UploadFile>;
  onHide: () => void;
}

export const AlgoStatusPanel = ({ activeFile, onHide }: Props) => {
  return (
    <Panel
      position="right"
      title="AlgoStatus"
      hidden={{
        placement: 'left',
        onHide,
      }}
    >
      <Layout direction="column" spacing={2}>
        {activeFile?.algoStatuses.map((algoStatus) => (
          <StatusMessage
            key={algoStatus.status}
            {...mapAlgoStatusMessageProps(algoStatus)}
          />
        ))}
      </Layout>
    </Panel>
  );
};
