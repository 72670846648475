import React from 'react';

import { Layout } from '../Layout';
import { AccordionItem } from './AccordionItem';
import { BaseItem, BorderMode, Item, PaddingMode } from './types';

interface Props<D = unknown> {
  /** Props used to render accordion headers and panels */
  items: Item<D>[];
  /** Border configuration */
  border?: BorderMode;
  /** If true, indents the details content relative to the summary */
  isIndented?: boolean;
  /** Padding configuration */
  padding?: PaddingMode;
  /** Search text for accordion title and search */
  search?: string;
  /** On toggle according section */
  onToggle?: (isExpanded: boolean, item: BaseItem<D>) => void;
}

export const Accordion = <D,>({
  border = 'all',
  isIndented = false,
  items,
  onToggle,
  padding = 'all',
  search,
}: Props<D>) => {
  return (
    <Layout direction="column" minW="accordion.min-width" w="100%">
      {items.map((item) => (
        <AccordionItem<D>
          key={item.title}
          border={border}
          isIndented={isIndented}
          item={item}
          padding={padding}
          search={search}
          onToggle={(isExpanded) => {
            onToggle?.(isExpanded, item);
          }}
        />
      ))}
    </Layout>
  );
};
