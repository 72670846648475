import { orderBy, sortBy } from 'lodash';

import { StageConfig } from '~/constants/ticket';
import {
  TicketStageType,
  TicketStatusType,
  WorkflowCreatorPermissionType,
} from '~/enums';

export function getStatusTextStatus(phaseStatus) {
  switch (phaseStatus) {
    case TicketStatusType.Approved:
      return 'success';
    case TicketStatusType.Rejected:
      return 'danger';
    case TicketStatusType.Pending:
    default:
      return 'warn';
  }
}

export function getSignTicketJudgments(ticket) {
  const { phases, stages } = ticket;
  const signPhaseIds = stages.sign.phaseIds;
  const signTicketJudgments = Object.entries(phases).reduce(
    (acc, [key, value]) => {
      if (signPhaseIds.includes(key)) {
        acc = [...acc, ...value.judgments];
      }
      return acc;
    },
    [],
  );
  return signTicketJudgments;
}

export function testIsUnassignedUser(approvalItem, users) {
  const hasExternalUser = approvalItem.externalUser;
  const hasApprover = approvalItem.userId;
  const hasNoApprover = !hasExternalUser && !hasApprover;
  const isRemovedUser = !!hasApprover && !users[approvalItem.userId];
  return hasNoApprover || isRemovedUser;
}

export function testIsCounterpartySigner(judgment) {
  return judgment && !judgment?.isInternal;
}

/**
 * Returns true if the approval item is from a CC Signer.
 * @param {ApprovalItem} approvalItem
 * @returns {boolean}
 */
export function testIsCcSigner(approvalItem) {
  return (
    approvalItem.status === TicketStatusType.NotApplicable ||
    !!(
      approvalItem.ccRecipients &&
      Object.keys(approvalItem.ccRecipients).length > 0
    )
  );
}

/*
For tickets created before the fix for EKP-2977: if the sign judgment has no approvalId,
it lacks of either email or name of the signer, making it an invalid signer.
After the fix for EKP-2977: if the approval has neither externalUser nor userId, or is a removed user,
then it is invalid.
*/
export function testHasInvalidSigners(ticket, users) {
  const signTicketJudgments = getSignTicketJudgments(ticket);

  return Object.values(signTicketJudgments).some((ticketJudgment) => {
    const hasApproval = ticketJudgment.approvals.length > 0;
    if (!hasApproval) {
      return true;
    } else {
      const approval = ticketJudgment.approvals[0];
      return !testIsCcSigner(approval) && testIsUnassignedUser(approval, users);
    }
  });
}

export const getEnabledStages = (ticket) => {
  const enabledStages = Object.entries(ticket.stages).reduce(
    (acc, [value, stage]) => {
      const { isEnabled } = stage;
      const { label, order } = StageConfig[value];
      if (isEnabled) {
        acc.push({
          label,
          order,
          value,
        });
      }
      return acc;
    },
    [],
  );

  return sortBy(enabledStages, ['order'], ['asc']);
};

export const getSimplifiedStageNames = (ticket) => {
  const { enabledStageNames } = ticket;
  const enabledStages = enabledStageNames.map((stageName) => {
    const { label, order } = StageConfig[stageName];
    return { label, order, value: stageName };
  });
  return sortBy(enabledStages, ['order'], ['asc']);
};

/**
 * Returns the WorkflowCreatorPermissionType for relating TicketStageType.  Returns null if unmapped.
 * @param {TicketStageType} stage
 * @returns {WorkflowCreatorPermissionType|null}
 */
export const mapTicketStageToWorkflowCreatorPermission = (stage) => {
  switch (stage) {
    case TicketStageType.Edit:
      return WorkflowCreatorPermissionType.Edit;
    case TicketStageType.Review:
      return WorkflowCreatorPermissionType.Review;
    case TicketStageType.Sign:
      return WorkflowCreatorPermissionType.Sign;
    case TicketStageType.Finalize:
      return WorkflowCreatorPermissionType.Finalize;
    case TicketStageType.Ticket:
      return WorkflowCreatorPermissionType.Ticket;
    default:
      return null;
  }
};

/**
 * Returns the permissions associated with a ticket stage.
 * @typedef {import('~/enums').TicketPermissionType} TicketPermissionType
 * @param {Object.<WorkflowCreatorPermissionType, TicketPermissionType[]>} permissionMap
 * @param {TicketStageType} stage
 * @returns {TicketPermissionType[]} Array of TicketPermissionType
 */
export const getStagePermissions = (permissionMap, stage) => {
  return permissionMap[mapTicketStageToWorkflowCreatorPermission(stage)] || [];
};

/**
 * @param {TicketStageType} stage The current stage
 * @param {TicketStageType[]} stages Array of stages
 * @returns {?TicketStageType} The next stage
 */
export const getNextStageType = (stage, stages) => {
  const sortedStages = orderBy(stages, ['order']);
  const index = sortedStages.findIndex(({ value }) => value === stage);

  return index < 0 ? null : sortedStages[index + 1]?.value ?? null;
};

/**
 * Returns the boolean of whether should ask user to fill in intake form questions or not.
 * @param {ticket} current ticket object
 * @param {users} array of user objects
 * @returns {boolean} should fill in form questions or not
 */
export function shouldFillFormQuestionsInSignStage(ticket, users) {
  if (
    ticket.stage === TicketStageType.Sign &&
    ticket.hasUnpopulatedSignerField &&
    ticket.stages.sign?.status !== TicketStatusType.Signed
  ) {
    const signTicketJudgments = getSignTicketJudgments(ticket);
    return signTicketJudgments.some((ticketJudgment) =>
      testIsUnassignedUser(ticketJudgment.approvals[0], users),
    );
  }

  return false;
}

/**
 * Returns the file extension of the ticket version
 * @param {ticket} ticket current ticket object from the state
 * @returns {string} the file extension with the dot, such as '.pdf', '.docx', etc.
 */
export function getTicketDocumentFileExtension(ticket) {
  const { document, documentVersions } = ticket;
  const fileType = documentVersions.find(
    (v) => v.versionNumber === document.version,
  )?.fileType;
  return fileType ? '.' + fileType : fileType;
}

/**
 * Returns false if the latest ticket version has a working draft
 * @param {ticket} ticket current ticket object from the state
 */
export function latestVersionHasWorkingDraft(documentVersions) {
  return documentVersions[0]?.hasWorkingDraft === true;
}

/**
 * Return versionNumber's version is a counterparty from upload version
 */
export function isCurrentVersionCounterparty(documentVersions, versionNumber) {
  const version = documentVersions.find(
    (v) => v.versionNumber === versionNumber,
  );
  return version?.isCounterparty === true;
}

export function getVersionFileName(document, version) {
  if (!document || !version) return '';
  const name = version.name ?? document.name;
  if (name) {
    const fileExt = version.fileType;
    return name.endsWith(fileExt) ? name : `${name}.${fileExt}`;
  }

  return '';
}

/**
 *
 * @param {string} fileName the name of the file
 * @param {string} extension the extension of the file without the  dot ('.')
 * @returns the file name without the extension if it has it.
 */
export const removeFileNameExtension = (fileName, extension) => {
  if (!fileName) return '';
  // +1 because extension doesn't consider the dot
  return fileName.endsWith(extension)
    ? fileName.slice(0, -(extension.length + 1))
    : fileName;
};
