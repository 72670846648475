import { capitalize } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { PersistedTable } from '~/components/Shared/PersistedTable';
import { User } from '~/components/Shared/User';
import { TableContextType } from '~/enums';
import { useTableSettings } from '~/hooks';
import { actions, api } from '~/redux';
import { RoutePathType, useRouting } from '~/routing';

import { ModelPreviewSearchFilters } from '../fields/shared/ModelPreviewSearchFilters';
import { FieldModel } from '../fields/types';
import { ModelProgress } from '../ModelProgress';
import { ModelActionType } from '../types';
import { testIsActivePublishStatus } from '../utils';

interface Props {
  isLoading: boolean;
  models: FieldModel[];
  pageIndex: number;
  totalCount: number;
}

export const ModelList = ({
  isLoading,
  models,
  pageIndex,
  totalCount,
}: Props) => {
  const { navigate, setSearchParams } = useRouting();
  const dispatch = useDispatch();

  const [
    updatePromptModelProgress,
    { isLoading: isUpdatingPromptModelProgress },
  ] = api.endpoints.updatePromptModelProgress.useMutation();

  const {
    tableSettings = {
      pageSize: 10,
    },
  } = useTableSettings(TableContextType.FieldAi);

  const { pageSize } = tableSettings;

  const columns = useMemo(
    () => [
      {
        key: 'name',
        cellType: 'link',
        disableSortBy: true,
        title: 'Field Model',
        mapCellProps: (model: FieldModel) => ({
          text: model.name,
          pathname: `${RoutePathType.AutomationHubFields}/${model.id}`,
          onClick: () => {
            if (model.optimizeState) {
              dispatch(
                actions.setFieldAiOptimize({
                  modelId: model.id,
                  version: model.version,
                }),
              );
            }
            navigate(model.id);
          },
        }),
      },
      {
        key: 'scope',
        cellType: 'text',
        disableSortBy: true,
        title: 'Scope',
        width: 'l',
        renderCell: (model: FieldModel) => (
          <ModelPreviewSearchFilters model={model} />
        ),
      },
      {
        key: 'docsInScope',
        align: 'right',
        disableSortBy: true,
        title: 'Documents in Scope',
        width: 'm',
        renderCell: (model: FieldModel) => (
          <ModelProgress metric="docsInScope" modelId={model.id} />
        ),
      },
      {
        key: 'valuesFound',
        align: 'right',
        disableSortBy: true,
        title: 'Values Found',
        width: 'm',
        renderCell: (model: FieldModel) => (
          <ModelProgress metric="valuesFound" modelId={model.id} />
        ),
      },
      {
        key: 'modifiedBy',
        cellType: 'user',
        disableSortBy: true,
        title: 'Modified By',
        mapCellProps: ({ modifiedBy }: FieldModel) => ({
          asyncUser: {
            id: modifiedBy,
            render: User,
          },
          mode: 'avatar-name',
        }),
      },
      {
        key: 'modifiedDate',
        cellType: 'datetime',
        disableSortBy: true,
        title: 'Modified Date',
        mapCellProps: ({ modifiedDate }: FieldModel) => ({
          datetime: modifiedDate,
          format: 'short',
        }),
      },
      {
        key: 'publishStatus',
        disableSortBy: true,
        title: 'Status',
        width: 'm',
        renderCell: (model: FieldModel) => (
          <ModelProgress metric="status" modelId={model.id} />
        ),
      },
    ],
    [],
  );

  const getRowActions = useCallback(
    (model: FieldModel) => {
      const { publishStatus } = model;
      const modelActions = [
        publishStatus === 'suspended'
          ? 'resume'
          : testIsActivePublishStatus(publishStatus)
          ? 'suspend'
          : null,
        'delete',
      ].filter((action) => action !== null) as ModelActionType[];

      const disabled = isLoading || isUpdatingPromptModelProgress;

      return modelActions.map((action) => ({
        key: action,
        disabled,
        label: capitalize(action),
        tooltip: disabled ? 'Updating model…' : undefined,
        onClick: () => {
          if (action === 'resume') {
            updatePromptModelProgress({ modelId: model.id });
            dispatch(actions.setFieldAiActiveModel(model));
          }
          dispatch(
            actions.setFieldAiModelAction({
              model: {
                id: model.id,
                name: model.name,
                entity: { id: '', type: 'field' }, // placeholder, not actually used
                version: model.version,
              },
              action,
            }),
          );
        },
      }));
    },
    [isLoading, isUpdatingPromptModelProgress],
  );

  const handlePaginate = useCallback(
    ({ pageIndex }: { pageIndex: number }) => {
      setSearchParams({
        pageIndex: String(pageIndex),
      });
    },
    [pageIndex],
  );

  const handleShowDetails = (model: FieldModel) => {
    dispatch(actions.setFieldAiActiveModel(model));
  };

  return (
    <PersistedTable
      name="field-model-list"
      columns={columns}
      context={TableContextType.FieldAi}
      data={models}
      getRowActions={getRowActions}
      options={{
        enableExportXlsx: false,
        enableSelectRows: false,
      }}
      reactTableOptions={{
        autoResetHiddenColumns: false,
        autoResetResize: false,
      }}
      state={{
        pageIndex,
        pageSize,
      }}
      totalCount={totalCount}
      onPaginate={handlePaginate}
      rowDetails={{
        onClick: handleShowDetails,
      }}
    />
  );
};
